<script setup>
import { readonly } from 'vue'

const emit = defineEmits(['modalClose'])
const props = defineProps({ data: Object })

const users = readonly(props.data.users)

function close() {
  emit('modalClose', true)
}
</script>

<template>
  <aside class="ht-modal">
    <div class="ht-dialog">
      <header>
        <h2>{{ props.data.title }}</h2>
        <button class="close" @click="close">
          <span class="sr-only">Close modal window</span>
        </button>
      </header>
      <main>
        <ol>
          <li v-for="user in users" :key="`userList_${user.id}`">
            <user-card :user="user" />
          </li>
        </ol>
      </main>
    </div>
  </aside>
</template>

<style lang="scss" scoped>
.ht-dialog {
  padding-bottom: 0 !important;
  max-width: 512px !important;
}

ol {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 15px;
  margin: 0;
  padding: 0;

  > li {
    display: block;
  }
}
</style>
