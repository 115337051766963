<script setup>
import { ref, computed, watch, toRef } from 'vue'

const props = defineProps({
  modelValue: Number,
  max: Number,
  min: Number,
  disabled: Boolean,
})
const emit = defineEmits(['update:modelValue'])

const disabled = toRef(props, 'disabled')

const maxVal = computed(() => (props.max !== undefined ? props.max : Infinity))
const minVal = computed(() =>
  props.min !== undefined ? props.min : Infinity * -1
)
const rawVal = ref(0)
const val = computed({
  get() {
    const n = parseInt(rawVal.value, 10)
    return isNaN(n) ? 0 : n
  },
  set(newVal) {
    if (typeof newVal === 'string' && newVal.trim() === '') rawVal.value = ''
    else
      rawVal.value = Math.min(
        maxVal.value,
        Math.max(minVal.value, parseInt(newVal, 10))
      )
  },
})

watch(rawVal, (v) => {
  emit('update:modelValue', v)
})

watch(
  props,
  () => {
    val.value = parseInt(props.modelValue, 10) || 0
  },
  { immediate: true }
)

function handleBlur() {
  if (val.value.length === 0) val.value = 0
}

function forceNumberKey($event) {
  const validKey =
    ($event.keyCode >= 48 && $event.keyCode <= 57) ||
    ($event.keyCode >= 96 && $event.keyCode <= 105) ||
    $event.keyCode === 37 ||
    $event.keyCode === 39 ||
    $event.keyCode === 8
  if (!validKey) $event.preventDefault()
}

function easeOut(t, b, c, d) {
  // eslint-disable-next-line no-restricted-properties, prefer-exponentiation-operator, no-return-assign
  return -c * ((t = t / d - 1) * t * t * t - 1) + b
}

let tweening = null
function startAdjustUp() {
  const startTime = Date.now()

  const tweenTick = () => {
    const elapsed = Date.now() - startTime
    const delay = easeOut(elapsed, 750, -725, 5000)

    val.value += 1

    tweening = setTimeout(() => {
      tweenTick()
    }, delay)
  }

  tweenTick()
}

function endAdjustUp() {
  if (tweening) clearTimeout(tweening)
}

function startAdjustDown() {
  const startTime = Date.now()

  const tweenTick = () => {
    const elapsed = Date.now() - startTime
    const delay = easeOut(elapsed, 750, -725, 5000)

    val.value -= 1

    tweening = setTimeout(() => {
      tweenTick()
    }, delay)
  }

  tweenTick()
}

function endAdjustDown() {
  if (tweening) clearTimeout(tweening)
}
</script>

<template>
  <div :class="['ht-number-spinner', { disabled }]">
    <div
      class="input"
      :contenteditable="!disabled"
      @blur="handleBlur"
      @input="val = $event.target.innerText"
      @keydown="forceNumberKey"
    >
      {{ val }}
    </div>
    <div class="adjustments">
      <button
        class="adjust-up"
        @mousedown.prevent="startAdjustUp"
        @mouseout.prevent="endAdjustUp"
        @mouseup.prevent="endAdjustUp"
      ></button>
      <button
        class="adjust-down"
        @mousedown.prevent="startAdjustDown"
        @mouseout.prevent="endAdjustDown"
        @mouseup.prevent="endAdjustDown"
      ></button>
    </div>
  </div>
</template>

<style lang="scss">
.ht-number-spinner {
  border: 1px solid $border-color;
  border-radius: 6px;
  display: grid;
  grid-template-columns: 1fr 30px;
  overflow: hidden;
  min-width: 0;
  flex: 1;
  background: #fff;

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  > .input {
    appearance: none;
    background: none;
    border: 0;
    outline: none;
    display: block;
    padding: 0.5rem 1rem;
    min-width: 70px;
    width: 100%;
    text-align: center;
  }

  > .adjustments {
    width: 30px;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;

    > button {
      display: block;
      padding: 0;
      appearance: none;
      border: 0;
      text-align: center;
      outline: none;
      background: none;
      height: 100%;
      width: 100%;
      cursor: pointer;
      font-family: 'Font Awesome 6 Pro';
      font-weight: 600;
      color: $muted-text;
      font-size: 0.8rem;

      &:hover {
        background: $gray-200;
        color: $body-color;
      }

      &::before {
        content: '\f0d8';
      }

      &.adjust-down {
        &::before {
          content: '\f0d7';
        }
      }
    }
  }
}
</style>
