<script setup>
import { debounce } from 'lodash-es'
import { ref, markRaw, watch, computed } from 'vue'
import { Team } from '@/api'
import { useToast } from 'vue-toastification'

const toast = useToast()

const props = defineProps({ multiple: { type: Boolean, default: false } })
const emit = defineEmits(['update:modelValue'])

const allowMultiple = ref(props.multiple)

const uploadedFiles = ref([])

const completedUploads = computed(() =>
  uploadedFiles.value
    .filter(({ status }) => status === 'DONE')
    .map((f) => ({ id: f.id, name: f.name }))
)

watch(
  () => completedUploads.value.length,
  () => {
    emit('update:modelValue', completedUploads.value)
  }
)

// eslint-disable-next-line no-unused-vars
const uploadPendingFiles = debounce(async () => {
  const toUpload = uploadedFiles.value.filter(
    ({ status }) => status === 'PENDING'
  )
  await Promise.all(
    toUpload.map(async (file) => {
      file.status = 'UPLOADING'

      const fd = new FormData()
      fd.append('file', file.file)

      const [err, resp] = await Team.uploadFile({
        formData: fd,
        onProgress: (progress) => {
          file.progress = progress
        },
      })

      if (err) file.status = 'ERROR'
      else {
        file.status = 'DONE'
        ;[file.id] = resp.files
      }
    })
  )
}, 250)

watch(uploadedFiles, uploadPendingFiles, { deep: true })

function addFilesToUpload(files) {
  if (!props.multiple && uploadedFiles.value.length > 0) {
    toast.error('Only one file may be uploaded.')
    return
  }

  uploadedFiles.value.push(
    ...[...files].map((file) => ({
      file: markRaw(file),
      status: 'PENDING',
      name: file.name,
    }))
  )
}

function handleFileDrop(e) {
  e.target.classList.remove('hovering')

  const { files } = e.dataTransfer

  if (!props.multiple && files.length > 1) {
    toast.error('Only one file may be uploaded.')
    return
  }

  addFilesToUpload(files)
}

async function removeFile(index) {
  const file = uploadedFiles.value[index]
  uploadedFiles.value[index].deleting = true

  await Team.deleteFile(file.id)

  uploadedFiles.value.splice(index, 1)
}

const fileInput = ref(null)

function openFilePicker() {
  const evt = new MouseEvent('click', {
    view: window,
    bubbles: true,
    cancelable: true,
  })

  fileInput.value.dispatchEvent(evt)
}

function handleFilePicker() {
  const { files } = fileInput.value

  addFilesToUpload(files)

  fileInput.value.value = null
}
</script>

<template>
  <div class="file-uploader">
    <input
      ref="fileInput"
      type="file"
      :multiple="props.multiple"
      class="file-input"
      @input="handleFilePicker"
    />
    <div
      class="drop-zone"
      @drop.prevent="handleFileDrop"
      @dragover.prevent
      @dragenter.prevent="$event.target.classList.add('hovering')"
      @dragleave.prevent="$event.target.classList.remove('hovering')"
    >
      <div>
        Drag &amp; drop {{ allowMultiple ? 'files' : 'a file' }} here, or
      </div>
      <button class="btn btn-white btn-sm" @click.prevent="openFilePicker">
        <i class="fas fa-plus"></i> Select a file
      </button>
    </div>

    <ul v-if="uploadedFiles.length > 0">
      <li v-for="(file, index) in uploadedFiles" :key="`uploadedFile_${index}`">
        <i class="fa-duotone fa-file"></i>
        <div>
          <span>{{ file.name }}</span>
          <div
            v-if="file.status === 'UPLOADING'"
            class="progress"
            role="progressbar"
          >
            <div
              :class="[
                'progress-bar bg-secondary',
                {
                  'progress-bar-striped progress-bar-animated':
                    file.progress === 1,
                },
              ]"
              :style="{ width: `${(file.progress || 0) * 100}%` }"
            ></div>
          </div>
          <div v-else class="status">
            <span v-if="file.status === 'PENDING'">Starting upload...</span>
            <span v-if="file.status === 'DONE'"
              ><i class="fas fa-check"></i> Uploaded</span
            >
          </div>
        </div>
        <button
          v-if="file.status === 'DONE'"
          class="btn btn-white btn-circle-sm"
          :disabled="file.deleting"
          @click.prevent="removeFile(index)"
        >
          <i v-if="!file.deleting" class="fas fa-times"></i>
          <loading-spinner v-else color="dark" width="10" height="10" />
        </button>
      </li>
    </ul>
  </div>
</template>

<style lang="scss" scoped>
.file-uploader {
  width: 100%;

  .file-input {
    visibility: hidden;
    width: 0;
    height: 0;
    position: absolute;
  }

  > .drop-zone {
    height: 60px;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='12' ry='12' stroke='%23EEEBE6FF' stroke-width='5' stroke-dasharray='6%2c 14' stroke-dashoffset='15' stroke-linecap='square'/%3e%3c/svg%3e");
    border-radius: 12px;
    background-color: $light;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 7px;

    &.hovering {
      background-color: #f3fffd;
    }

    > div {
      font-size: 1rem;
      color: $muted-text;
      pointer-events: none;
    }
  }

  > ul {
    padding: 0;
    margin: 10px 0;
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 10px;

    > li {
      display: grid;
      width: 100%;
      font-size: 14px;
      font-weight: 600;
      padding: 0 0 0 30px;
      position: relative;
      height: 35px;
      grid-template-columns: 1fr 20px;
      align-items: center;
      column-gap: 25px;
      row-gap: 2px;

      > i {
        display: block;
        font-size: 22px;
        position: absolute;
        left: 0;
        top: 0;
        line-height: 35px;
      }

      > div {
        .progress {
          height: 7px;
          margin: 3px 0 0;
        }

        > span {
          display: block;
          line-height: 14px;
          margin: 0 0 2px;
        }

        > .status {
          line-height: 10px;
          display: flex;
          font-size: 10px;
          font-weight: normal;
          color: $muted-text;
        }
      }

      > button {
        font-size: 10px;
      }
    }
  }
}
</style>
