<script setup>
import { messageTemplateToHTML } from '@/utils'
import { onMounted, ref, toRef, watch } from 'vue'
import { debounce } from 'lodash-es'
import PostMeta from '@/components/PostMeta.vue'
import { AdminCelebrations } from '@/api'

const props = defineProps({
  data: Object,
  previewUsers: {
    type: Array,
    default: () => [],
  },
  preview: {
    type: Boolean,
    default: () => false,
  },
})

const celebration = ref({ ...props.data })
const rendering = ref(false)
const cardRendering = ref(false)
const messageContent = ref(null)
const generatedCard = ref(null)

const previewUsers = toRef(props, 'previewUsers')

watch(
  props.data,
  (v) => {
    celebration.value = { ...v }
  },
  { deep: true }
)

const isPreview = ref(props.preview)

async function renderMessage() {
  const elem = messageContent.value

  let nodes
  if (isPreview.value === true) {
    // Pass template through recognition service to generate preview
    const [err, resp] = await AdminCelebrations.getMessagePreview({
      userIds: previewUsers.value.map(({ id }) => id),
      messageTemplate: {
        message: celebration.value.message,
        name_template: celebration.value.name_template,
      },
      messageType: celebration.value.type,
    })
    celebration.value.users = previewUsers.value

    if (err) {
      // Handle error
    }

    nodes = messageTemplateToHTML({
      post: { message: resp.message },
      relevantUsers: celebration.value.users,
    })
  } else {
    nodes = messageTemplateToHTML({
      post: celebration.value,
      relevantUsers: celebration.value.users,
    })
  }

  elem.replaceChildren(...nodes)

  rendering.value = false
}

async function generateCardPreview() {
  if (isPreview.value !== true) return

  if (!celebration.value.card_template) return

  const [cardErr, cardResp] = await AdminCelebrations.getGeneratedCardPreview({
    userIds: previewUsers.value.map(({ id }) => id),
    cardTemplate: celebration.value.card_template,
    messageType: celebration.value.type,
  })

  if (cardErr) {
    // HAndle error
  }

  generatedCard.value = URL.createObjectURL(cardResp)
  cardRendering.value = false
}

const reRenderContent = debounce(renderMessage, 500)
const reGenerateCardPreview = debounce(generateCardPreview, 500)

watch(
  () => celebration.value.message + celebration.value.name_template,
  () => {
    rendering.value = true
    reRenderContent()
  }
)

watch(
  () => celebration.value.card_template,
  () => {
    cardRendering.value = true
    reGenerateCardPreview()
  }
)

watch(
  previewUsers,
  () => {
    console.log(previewUsers.value)
    rendering.value = true
    cardRendering.value = true
    reRenderContent()
    reGenerateCardPreview()
  },
  { deep: true }
)

onMounted(() => {
  renderMessage()
  generateCardPreview()
})
</script>

<template>
  <div :class="['celebration-announcement']">
    <div v-if="!isPreview" class="header">
      <div class="users">
        <user-card
          v-if="celebration.sender !== 'BOT'"
          :user="celebration.sender"
        ></user-card>
      </div>
      <post-meta :post="celebration" />
    </div>

    <div
      v-if="isPreview && celebration.card_template"
      :class="['post-card', 'preview', { rendering: cardRendering }]"
      :style="{
        'background-image': generatedCard
          ? `url('${generatedCard}')`
          : `url('${celebration.card_template_preview?.thumbnail}')`,
      }"
    ></div>
    <div
      v-if="celebration.generated_card"
      class="post-card"
      :style="{ 'background-image': `url('${celebration.generated_card}')` }"
    ></div>

    <div ref="messageContent" :class="['content', { rendering }]"></div>

    <template v-if="props.preview !== true">
      <ReactionBar :entity-id="celebration.id" />
      <CommentBar :entity-id="celebration.id" />
    </template>
  </div>
</template>

<style lang="scss" scoped>
.celebration-announcement {
  display: block;

  > .reaction-bar {
    margin: 15px 0 0;
  }

  > .comment-bar {
    margin: 20px 0 0;
  }

  > .header {
    display: grid;
    grid-template-columns: 1fr min-content;

    > .users {
      display: flex;
      align-items: center;

      .user--card {
        font-size: 14px;

        .timestamp {
          font-size: 12px;
          color: $muted-text;
          line-height: 12px;
          margin: 3px 0 0;
          display: block;
        }
      }

      > i {
        display: block;
        margin: 0 15px;
        color: $gray-300;
      }

      > ul {
        display: flex;
        padding: 0;
        margin: 0;

        > li {
          display: block;
        }
      }
    }
  }

  .post-card {
    overflow: hidden;
    border-radius: $border-radius;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    margin: 15px 0;
    position: relative;

    &::before {
      content: '';
      padding: 0 0 56.25%;
      display: block;
    }

    &.preview {
      &::after {
        content: 'PREVIEW';
        background: rgba($light, 0.6);
        padding: 0 5px;
        line-height: 14px;
        font-size: 12px;
        text-transform: uppercase;
        position: absolute;
        right: 15px;
        top: 10px;
        border-radius: 4px;
      }
    }

    &.rendering {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  .content {
    &.rendering {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  &.preview {
    > .header {
      display: block;
    }
  }
}
</style>
