<script setup>
import { nameToEmoji } from 'gemoji'
import { createPopper } from '@popperjs/core'
import { onMounted, ref, readonly } from 'vue'
import { debounce } from 'lodash-es'

const allowedReactions = readonly([
  'smile',
  'rofl',
  'wink',
  'smiling_face_with_three_hearts',
  'zany_face',
  'hugs',
  'saluting_face',
  'grimacing',
  'hot_face',
  'cowboy_hat_face',
  'partying_face',
  'sunglasses',
  'astonished',
  'cry',
  'rage',
  'heart',
  '100',
  'pinched_fingers',
  'thumbsup',
  'raised_hands',
  'clap',
  'nail_care',
])

const reactionPopover = ref(null)
const popoverVisible = ref(false)
let reactionTarget
let popper

const currentReactionBar = {
  getBoundingClientRect: () => ({
    width: 0,
    height: 0,
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  }),
}

const hidePopover = () => {
  currentReactionBar.getBoundingClientRect = () => ({
    width: 0,
    height: 0,
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  })

  popoverVisible.value = false
  reactionTarget = undefined

  popper.update()
}

const showPopover = (target) => {
  popoverVisible.value = true
  reactionTarget = target

  currentReactionBar.getBoundingClientRect = () =>
    target.getBoundingClientRect()

  popper.update()
}

const debounced = debounce((e, domPath) => {
  const { target } = e

  if (domPath.some((elem) => elem === reactionPopover.value)) debounced.cancel()
  else if (target.classList.contains('reaction-bar-add-reaction'))
    showPopover(target)
  else hidePopover()
}, 250)

const handleMouseHover = (event) => {
  debounced(event, event.composedPath())
}

onMounted(() => {
  document.querySelector('html').addEventListener('mouseover', handleMouseHover)

  popper = createPopper(currentReactionBar, reactionPopover.value, {
    placement: 'right-start',
    strategy: 'fixed',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 10],
        },
      },
    ],
  })
})

function selectReaction(emoji) {
  // Emit custom event on target
  reactionTarget.dispatchEvent(new CustomEvent('react', { detail: { emoji } }))
  hidePopover()
}
</script>

<template>
  <div
    ref="reactionPopover"
    :class="[
      'reaction-dropdown',
      { 'reaction-dropdown--hidden': !popoverVisible },
    ]"
  >
    <ul>
      <li v-for="ar in allowedReactions" :key="`addReaction_${ar}`">
        <a
          href="#"
          @click.prevent="selectReaction(ar)"
          v-html="nameToEmoji[ar]"
        ></a>
      </li>
    </ul>
  </div>
</template>

<style lang="scss" scoped>
.reaction-dropdown {
  display: block;
  position: fixed;
  background: #fff;
  border-radius: $border-radius;
  box-shadow: rgba(0, 0, 0, 8%) 0 1px 1px 0, $border-color 0 0 0 1px,
    rgba(0, 0, 0, 0.05) 0 12px 16px;
  min-width: 200px;
  min-height: 50px;
  z-index: 99;
  padding: 15px;

  &--hidden {
    visibility: hidden;
    pointer-events: none;
    z-index: -1;
  }

  > ul {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    padding: 0;
    column-gap: 5px;
    row-gap: 5px;
    margin: 0;

    > li {
      display: block;
      padding: 0;
      margin: 0;

      > a {
        display: block;
        font-size: 26px;
        text-decoration: none;
        width: 40px;
        height: 40px;
        line-height: 40px;
        border-radius: 12px;
        text-align: center;

        &:hover {
          background: $gray-200;
        }
      }
    }
  }
}
</style>
