import { loadStripe } from '@stripe/stripe-js'

const { VITE_STRIPE_PUB_KEY } = import.meta.env

export default {
  install: (app) => {
    const initStripe = async () => {
      if (!VITE_STRIPE_PUB_KEY) return console.warn('Stripe key not found.')

      const stripe = await loadStripe(VITE_STRIPE_PUB_KEY)
      app.config.globalProperties.$stripe = stripe

      app.provide('stripe', stripe)

      return stripe
    }

    initStripe()
  },
}
