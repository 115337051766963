<script setup>
import { useModal } from '@/modal'
import { ref } from 'vue'

const { openModal } = useModal()

const props = defineProps({ data: Object })
const nomination = ref(props.data)

const viewNomination = async () => {
  await openModal(
    import('@/components/admin/modals/CampaignNomination.vue'),
    nomination
  )
}
</script>
<template>
  <span
    ><strong>{{ nomination.created_by.first_name }}</strong> nominated
    <strong>{{ nomination.for_user.full_name }}</strong> for the
    <a href="#" @click.prevent="viewNomination">{{
      nomination.nomination_campaign_id.title
    }}</a>
    nomination campaign.</span
  >
</template>
