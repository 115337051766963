<script setup>
import { User } from '@/api'
import ProfilePicture from '@/components/userCard/ProfilePicture.vue'
import { useAppStore } from '@/store'
import { ref } from 'vue'

const appStore = useAppStore()

const props = defineProps({ data: Object })
const emit = defineEmits(['modalClose', 'modalDone'])

const user = ref(null)
const userId = ref(props.data.id)

async function populateUser() {
  const [err, resp] = await User.getByID(userId.value)

  if (err) {
    // TODO: Handle error

    return
  }

  user.value = resp
}
populateUser()

function close() {
  emit('modalClose', true)
}

function openShoutoutComposer() {
  appStore.openShoutoutComposer(user.value)
  emit('modalClose', true)
}
</script>

<template>
  <aside class="ht-modal">
    <div class="ht-dialog">
      <header>
        <button class="close" @click="close">
          <span class="sr-only">Close modal window</span>
        </button>
      </header>
      <main>
        <template v-if="user">
          <div class="title-wrap">
            <profile-picture :user="user" />
            <h2>{{ user.full_name }}</h2>
          </div>

          <div class="d-flex mt-4">
            <ul class="attributes">
              <li>
                <i class="fas fa-envelope"></i>
                <span
                  ><a :href="`mailto:${user.email}`">{{ user.email }}</a></span
                >
              </li>
            </ul>

            <div class="actions ms-auto">
              <button
                class="btn btn-primary"
                @click.prevent="openShoutoutComposer"
              >
                Send a shoutout
              </button>
            </div>
          </div>
        </template>
        <loading-spinner v-else color="dark" />
      </main>
    </div>
  </aside>
</template>

<style lang="scss" scoped>
.ht-dialog {
  padding-top: 0 !important;

  > header {
    background-color: transparent !important;
    border-bottom: 0 !important;
    box-shadow: none !important;

    .close {
      background: $light !important;
    }
  }

  > main {
    > .title-wrap {
      display: flex;
      column-gap: 10px;
      align-items: center;

      > .user--profile-picture {
        flex: 0 0 70px;
      }

      > h2 {
        margin: 0;
      }
    }

    .attributes {
      display: flex;
      padding: 0;

      > li {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        column-gap: 5px;

        > i {
          display: block;
          color: $muted-text;
        }

        > span {
          display: block;
          font-size: 14px;
        }
      }
    }
  }
}
</style>
