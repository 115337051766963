/* eslint-disable no-underscore-dangle */
// eslint-disable-next-line no-unused-vars
import { Dropdown, Popover, Tooltip } from 'bootstrap'
import { onMounted, onUnmounted, nextTick, onBeforeUnmount } from 'vue'
import { onBeforeRouteUpdate } from 'vue-router'
import { DateTime } from 'luxon'
import { toHTML } from 'slack-markdown'
import { useAppStore, useUserStore } from '@/store'
import Pipeline from 'pipeline-js'
import { debounce, get } from 'lodash-es'
import { storeToRefs } from 'pinia'
import { REWARD_COUNTRY_AVAILABILITY } from './constants'

let numberFormatter = null
let listFormatter = null
let pluralFormatter = null

let appStore

export function useFeatureAccess() {
  const userStore = useUserStore()
  const { user } = storeToRefs(userStore)

  return (feature) => {
    let checkPlan = []
    switch (feature) {
      case 'Shoutouts':
        checkPlan = ['PRO', 'CORE']
        break
      case 'Nominations':
        checkPlan = ['PRO']
        break
      case 'Challenges':
        checkPlan = ['PRO']
        break
      case 'SpotCelebrations':
        checkPlan = ['PRO', 'CELEBRATE']
        break
      default:
        break
    }

    return checkPlan.includes(user.value.subscription_type)
  }
}

export function useRewardAvailability() {
  const userStore = useUserStore()
  const { user } = storeToRefs(userStore)

  return (catalog) => {
    const [, userCountry] = user.value.locale.split('-')
    switch (catalog) {
      case 'AMAZON':
        return REWARD_COUNTRY_AVAILABILITY.AMAZON.includes(userCountry)
      case 'MARKETPLACE':
        return REWARD_COUNTRY_AVAILABILITY.MARKETPLACE.includes(userCountry)
      case 'CASHOUT':
        return REWARD_COUNTRY_AVAILABILITY.CASHOUT.includes(userCountry)

      default:
        break
    }

    return true
  }
}

export function useHelpWidget() {
  const userStore = useUserStore()
  const { user } = storeToRefs(userStore)

  onMounted(() => {
    if (!userStore.beenPopulated) return
    // eslint-disable-next-line no-undef
    groove.widget.identifyContact('contact_email', user.value.email)

    // eslint-disable-next-line no-undef
    groove.widget.setContact({
      email: user.value.email,
      name: user.value.full_name,
      customer_id: user.value.user_id,
      customer_team: user.value.team,
    })
  })

  return {
    // eslint-disable-next-line no-undef
    open: () => groove.widget.open(),
    // eslint-disable-next-line no-undef
    close: () => groove.widget.close(),
  }
}

export function useLocaleFormatter() {
  const initLocale = () => {
    appStore = useAppStore()
    numberFormatter = new Intl.NumberFormat('en-US')
    listFormatter = new Intl.ListFormat('en-US')
    pluralFormatter = new Intl.PluralRules('en-US', { type: 'ordinal' })
  }

  const ordinalSuffixes = new Map([
    ['one', 'st'],
    ['two', 'nd'],
    ['few', 'rd'],
    ['other', 'th'],
  ])

  if (!numberFormatter || !listFormatter || !pluralFormatter) initLocale()

  return {
    formatPoints: (points = 0, includeSuffix = true) => {
      const userStore = useUserStore()
      const { team } = storeToRefs(userStore)
      let pointsSuffix = ''
      if (team.value.point_name === 'points') {
        pointsSuffix = ` point${points === 1 ? '' : 's'}`
      } else {
        pointsSuffix = ` ${team.value.point_name}`
      }

      return `${numberFormatter.format(
        isNaN(points) || points === undefined || points === null
          ? 0
          : points.toFixed(0)
      )}${includeSuffix === false ? '' : pointsSuffix}`
    },
    formatCurrency: (amount, currencyCode = 'USD') => {
      const currencyFormatter = new Intl.NumberFormat(undefined, {
        style: 'currency',
        currency: currencyCode,
        trailingZeroDisplay: 'stripIfInteger',
      })

      return currencyFormatter.format(amount)
    },
    formatList: (items) => listFormatter.format(items),
    formatNumber: (number) => numberFormatter.format(number),
    numberWithOrdinal: (number) =>
      `${number}${ordinalSuffixes.get(pluralFormatter.select(number))}`,
    formatDate: (date, format = DateTime.DATE_MED) =>
      date.toLocaleString(format, { locale: 'en-us' }),
    humanizedDateDiff: (date) => {
      if (DateTime.now() - date < 6000) return 'just now'
      return date.toRelative()
    },
    currencyToPoints: (amount, currencyCode = 'USD') => {
      const currencyRate = appStore.currencyRates[currencyCode]

      const convertedAmt = amount / currencyRate

      return Math.ceil(convertedAmt * 100)
    },
    formatEIN: (ein) => ein.replace(/(\d{2})-?(\d{7})/gim, '$1-$2'),
  }
}

export function useSegmentColors() {
  const colors = [
    '#3366cc',
    '#dc3912',
    '#ff9900',
    '#109618',
    '#990099',
    '#0099c6',
    '#dd4477',
    '#66aa00',
    '#b82e2e',
    '#316395',
    '#3366cc',
    '#994499',
    '#22aa99',
    '#aaaa11',
    '#6633cc',
    '#e67300',
    '#8b0707',
    '#651067',
    '#329262',
    '#5574a6',
    '#3b3eac',
    '#b77322',
    '#16d620',
    '#b91383',
    '#f4359e',
    '#9c5935',
    '#a9c413',
    '#2a778d',
    '#668d1c',
    '#bea413',
    '#0c5922',
    '#743411',
  ]

  return {
    segmentColors: (str) => {
      let hash = 0
      if (str.length === 0) return hash
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < str.length; i++) {
        // eslint-disable-next-line no-bitwise
        hash = str.charCodeAt(i) + ((hash << 5) - hash)
        // eslint-disable-next-line no-bitwise
        hash &= hash
      }
      hash = ((hash % colors.length) + colors.length) % colors.length
      const color = colors[hash]
      return [{ '--tagColor': color }, { '--tagColorBG': `${color}1A` }]
    },
  }
}

export function useMakeID() {
  return {
    makeID: () =>
      (performance.now().toString(36) + Math.random().toString(36)).replace(
        /\./g,
        ''
      ),
  }
}

export function useMarketplacePrices() {
  const { formatPoints } = useLocaleFormatter()

  const MARKETPLACE_PRICE_RANGES = [
    { label: `Under ${formatPoints(2000)}`, value: [0, 2000] },
    {
      label: `${formatPoints(2000)} to ${formatPoints(5000)}`,
      value: [2000, 5000],
    },
    {
      label: `${formatPoints(5000)} to ${formatPoints(10000)}`,
      value: [5000, 10000],
    },
    {
      label: `${formatPoints(10000)} to ${formatPoints(20000)}`,
      value: [10000, 20000],
    },
    { label: `Over ${formatPoints(20000)}`, value: [20000, null] },
  ]

  return MARKETPLACE_PRICE_RANGES
}

export function useBSPopover() {
  const initPopovers = () => {
    const popoverTriggerList = document.querySelectorAll(
      '[data-bs-toggle="popover"]'
    )
    popoverTriggerList.forEach(
      (popoverTriggerEl) => new Popover(popoverTriggerEl)
    )
  }
  onMounted(initPopovers)

  return { Popover, initPopovers }
}

export function useNavDropdowns() {
  let dropdownList = []

  function handleMouseEnter(e) {
    const instance = Dropdown.getInstance(
      e.target.querySelector('.dropdown-toggle')
    )
    instance._debouncers[1].cancel()
    instance._debouncers[0](instance)
  }

  function handleMouseLeave(e) {
    const instance = Dropdown.getInstance(
      e.target.querySelector('.dropdown-toggle')
    )
    instance._debouncers[0].cancel()
    instance._debouncers[1](instance)
  }

  onBeforeRouteUpdate(() => {
    // Close all dropdowns
    dropdownList.forEach((dropdown) => {
      dropdown.hide()
    })
  })

  onBeforeUnmount(() => {
    dropdownList.forEach((dropdown) => {
      dropdown._element.parentElement.removeEventListener(
        'mouseenter',
        handleMouseEnter
      )
      dropdown._element.parentElement.removeEventListener(
        'mouseleave',
        handleMouseLeave
      )

      dropdown.dispose()
    })
  })

  onMounted(() => {
    const dropdownElementList = document.querySelectorAll(
      '.primary-nav>ul>.dropdown>.dropdown-toggle'
    )

    dropdownList = [...dropdownElementList].map(
      (dropdownToggleEl) =>
        new Dropdown(dropdownToggleEl, {
          popperConfig: (defaultConfig) => ({
            ...defaultConfig,
            modifiers: [
              {
                name: 'computeStyles',
                phase: 'write',
                enabled: true,
                fn({ state }) {
                  state.styles.popper = {
                    ...state.styles.popper,
                    transform: 'translate3d(-50%, 26px, 0)',
                    left: '50%',
                  }

                  return state
                },
              },
            ],
          }),
        })
    )

    dropdownList.forEach((dropdown) => {
      // Add hover listener
      dropdown._debouncers = [
        debounce((instance) => instance.show(), 250),
        debounce((instance) => instance.hide(), 250),
      ]

      dropdown._listeners = [
        dropdown._element.parentElement.addEventListener(
          'mouseenter',
          handleMouseEnter
        ),
        dropdown._element.parentElement.addEventListener(
          'mouseleave',
          handleMouseLeave
        ),
      ]
    })
  })
}

export function useBSDropdown() {
  onMounted(() => {
    const dropdownElementList = document.querySelectorAll('.dropdown-toggle')
    dropdownElementList.forEach(
      (dropdownToggleEl) => new Dropdown(dropdownToggleEl)
    )
  })

  return { Dropdown }
}

export function parseHtmlToShoutout(html) {
  const parsed = new DOMParser().parseFromString(html, 'text/html').body

  const mentions = parsed.querySelectorAll('.mention')

  const formatted = parsed.cloneNode(true)
  formatted.querySelectorAll('.mention').forEach((el) => {
    el.replaceWith(
      document.createTextNode(`<@${el.getAttribute('data-user-id')}>`)
    )
    // el.remove()
  })

  formatted.querySelectorAll('br').forEach((el) => {
    el.replaceWith(document.createTextNode(`\n`))
  })

  return {
    mentioned_users: [...mentions].map((el) => ({
      id: el.getAttribute('data-user-id'),
      full_name: el.getAttribute('data-user-name'),
    })),
    plain_text: parsed.textContent,
    formatted_text: formatted.textContent,
  }
}

export function parseHtmlToMessageTemplate(html) {
  const parsed = new DOMParser().parseFromString(html, 'text/html').body

  parsed.querySelectorAll('.data-tag').forEach((el) => {
    el.replaceWith(document.createTextNode(`{{${el.textContent}}}`))
  })

  parsed.querySelectorAll('br').forEach((el) => {
    el.replaceWith(document.createTextNode(`\n`))
  })

  return parsed.textContent
}

export function useAppendMultiselectToBody() {
  let elem
  let wrapper
  const positionDropdown = () => {
    const { top, height, left } = elem.getBoundingClientRect()

    wrapper.style.width = `${elem.clientWidth}px`
    wrapper.style.position = 'fixed'
    wrapper.style.zIndex = '999999'

    wrapper.style.left = `${left}px`

    if (elem.classList.contains('multiselect--above')) {
      wrapper.style.display = 'flex'
      nextTick(() => {
        const wrapperHeight = wrapper.getBoundingClientRect().height
        wrapper.style.display = 'none'

        wrapper.style.top = `${top + 2 - wrapperHeight}px`
        wrapper.style.bottom = 'auto'
      })
    } else {
      wrapper.style.top = `${top + height}px`
      wrapper.style.bottom = 'auto'
    }
  }

  const vAppendToBody = {
    mounted: (el) => {
      nextTick(() => {
        elem = el
        wrapper = el.querySelector('.multiselect__content-wrapper')

        document.body.appendChild(wrapper)
      })
    },
  }
  return {
    vAppendToBody,
    positionDropdown,
  }
}

export function messageToNodes({
  message,
  populateTags = false,
  tagData = undefined,
  nameTemplate = '{{name}}',
}) {
  const populateMsgTags = (text) => {
    if (typeof text !== 'string' || text.length === 0) return ''

    const { formatList } = useLocaleFormatter()
    // Replace tags in text with data
    const regex = /{{(\S*?)}}/gim

    // TODO: Break these tag functions out so we can set some default tags
    const defaultTagData = {
      names: formatList(
        tagData.relevantUsers.map((u) => {
          const userTagData = {
            ...u,
            name: `<@${u.id}>`,
          }
          return nameTemplate.replace(regex, (str, tagName) =>
            get(userTagData, tagName, '')
          )
        })
      ),
      ...tagData,
    }

    return text.replace(regex, (str, tagName) =>
      get(defaultTagData, tagName, '')
    )
  }

  const replaceMentions = (msgNodes) => {
    const { relevantUsers } = tagData

    const nodes = []
    const regex = /<@(.*?)>/g

    msgNodes.forEach((node) => {
      if (node.nodeType !== 3) {
        nodes.push(node)
        return
      }

      const text = node.textContent
      let cursor = 0
      let mention
      // eslint-disable-next-line no-cond-assign
      while ((mention = regex.exec(text)) !== null) {
        // eslint-disable-next-line no-loop-func
        const user = relevantUsers.find((u) => u.id === mention[1])

        const textNode = document.createTextNode(
          text.substring(cursor, mention.index)
        )
        nodes.push(textNode)

        cursor = mention.index + mention[0].length

        if (!user) {
          const invalidUser = document.createElement('span')
          invalidUser.className = 'invalid-user'
          nodes.push(invalidUser)

          continue
        }

        // Add mention dom
        const mentionNode = document.createElement('a')
        mentionNode.innerText = `@${user.full_name}`
        mentionNode.href = '#'
        mentionNode.className = 'user-mention'
        mentionNode.$HiThriveUser = user

        nodes.push(mentionNode)
      }

      const endMsg = text.substring(cursor)
      if (endMsg) nodes.push(document.createTextNode(endMsg))
    })

    return nodes
  }

  // eslint-disable-next-line no-unused-vars
  const replaceLineBreaks = (text) => {
    const regex = /\n/gim
    const nodes = []

    let lineBreak
    let cursor
    // eslint-disable-next-line no-cond-assign
    while ((lineBreak = regex.exec(text)) !== null) {
      // eslint-disable-next-line no-loop-func

      const textNode = document.createTextNode(
        text.substring(cursor, lineBreak.index)
      )
      nodes.push(textNode)

      cursor = lineBreak.index + 1

      const br = document.createElement('br')
      nodes.push(br)
    }

    const restOfText = text.substring(cursor)
    if (restOfText) nodes.push(document.createTextNode(restOfText))

    return nodes
  }

  const addMarkdownFormatting = (text) => {
    const html = toHTML(text, {
      noExtraSpanTags: true,
      escapeHTML: false,
      slackCallbacks: {
        user: ({ id }) => `<@${id}>`,
      },
    })

    const template = document.createElement('template')
    template.innerHTML = html

    console.log(template.content.childNodes)

    return template.content.childNodes
  }

  const pipeline = []
  if (populateTags === true) pipeline.push(populateMsgTags)

  pipeline.push(...[addMarkdownFormatting])

  if (populateTags === true) pipeline.push(replaceMentions)

  const res = new Pipeline(pipeline).process(message)
  return res
}

export function shoutoutToNodes(shoutout) {
  const msg = shoutout.message

  // eslint-disable-next-line no-unused-vars
  const replaceLineBreaks = (text) => {
    const regex = /\n/gim
    const nodes = []

    let lineBreak
    let cursor
    // eslint-disable-next-line no-cond-assign
    while ((lineBreak = regex.exec(text)) !== null) {
      // eslint-disable-next-line no-loop-func

      const textNode = document.createTextNode(
        text.substring(cursor, lineBreak.index)
      )
      nodes.push(textNode)

      cursor = lineBreak.index + 1

      const br = document.createElement('br')
      nodes.push(br)
    }

    const restOfText = text.substring(cursor)
    if (restOfText) nodes.push(document.createTextNode(restOfText))

    return nodes
  }

  const addMarkdownFormatting = (text) => {
    const html = toHTML(text, {
      noExtraSpanTags: true,
      escapeHTML: false,
      slackCallbacks: {
        user: ({ id }) => `<@${id}>`,
      },
    })

    const template = document.createElement('template')
    template.innerHTML = html

    console.log(template.content.childNodes)

    return template.content.childNodes
  }

  const replaceMentions = (msgNodes) => {
    const relevantUsers = shoutout.relevant_users

    const nodes = []
    const regex = /<@(.*?)>/g

    msgNodes.forEach((node) => {
      if (node.nodeType !== 3) {
        nodes.push(node)
        return
      }

      const text = node.textContent
      let cursor = 0
      let mention
      // eslint-disable-next-line no-cond-assign
      while ((mention = regex.exec(text)) !== null) {
        // eslint-disable-next-line no-loop-func
        const user = relevantUsers.find((u) => u.id === mention[1])

        const textNode = document.createTextNode(
          text.substring(cursor, mention.index)
        )
        nodes.push(textNode)

        cursor = mention.index + mention[0].length

        if (!user) {
          const invalidUser = document.createElement('span')
          invalidUser.className = 'invalid-user'
          nodes.push(invalidUser)

          continue
        }

        // Add mention dom
        const mentionNode = document.createElement('a')
        mentionNode.innerText = `@${user.full_name}`
        mentionNode.href = '#'
        mentionNode.className = 'user-mention'
        mentionNode.$HiThriveUser = user

        nodes.push(mentionNode)
      }

      const endMsg = text.substring(cursor)
      if (endMsg) nodes.push(document.createTextNode(endMsg))
    })

    return nodes
  }

  const res = new Pipeline([addMarkdownFormatting, replaceMentions]).process(
    msg
  )

  return res
}

export function messageTemplateToHTML({ post, relevantUsers, attributes }) {
  const { message } = post
  const nodes = messageToNodes({
    message,
    populateTags: true,
    tagData: {
      relevantUsers,
      ...attributes,
    },
    nameTemplate: post.name_template,
  })

  return nodes
}

export function useScrollVar() {
  const elems = new Set()

  const handleElemScroll = (e) => {
    const { scrollTop, scrollLeft } = e.target
    e.target.setAttribute(
      'style',
      `--scrollTop: ${scrollTop}px; --scrollLeft: ${scrollLeft}px;`
    )
  }

  onUnmounted(() => {
    elems.forEach((elem) =>
      elem.removeEventListener('scroll', handleElemScroll)
    )
    elems.clear()
  })

  return {
    addScrollVar: (el) => {
      if (!el) return

      elems.add(el)
      el.addEventListener('scroll', handleElemScroll)
    },
  }
}

export function getNumberSuffix(num) {
  const th = 'th'
  const rd = 'rd'
  const nd = 'nd'
  const st = 'st'

  if (num === 11 || num === 12 || num === 13) return th

  const lastDigit = num.toString().slice(-1)

  switch (lastDigit) {
    case '1':
      return st
    case '2':
      return nd
    case '3':
      return rd
    default:
      return th
  }
}
