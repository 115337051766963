import { DateTime } from 'luxon'
import { defineStore } from 'pinia'
import jwtDecode from 'jwt-decode'
import apiClient, { User, Team } from '@/api'

// eslint-disable-next-line import/no-cycle
import { useAppStore } from './appStore'

const getAuthedUser = () => {
  const token = window.localStorage.getItem('hithrive_user')
  if (!token) return null

  // Decode token
  const decoded = jwtDecode(token)
  if (decoded.exp <= DateTime.utc().toSeconds()) {
    window.localStorage.removeItem('hithrive_user')
    return null
  }

  return decoded
}

export const useUserStore = defineStore('user', {
  state: () => ({
    user: getAuthedUser(),
    team: null,
  }),
  getters: {
    isAdmin: (state) => state.user?.roles.includes('admin'),
    beenPopulated: (state) => state.user?.id !== undefined,
    teamPopulated: (state) => state.team !== null,
    userHasRole: (state) => (role) => state.user?.roles.includes(role),
    rewardCountry: (state) => {
      if (!state.user) return null

      const { locale } = state.user
      if (!locale) return null

      const [, country] = locale.split('-')

      return country.toUpperCase()
    },
  },
  actions: {
    async populateTeamMeta(addlArgs = {}) {
      const [err, meta] = await Team.getMetaInfo(addlArgs)
      if (err) return

      this.team = { ...this.team, ...meta }
    },
    async populateUser(include) {
      const appStore = useAppStore()
      if (this.user === null) return

      const [err, user] = await User.getCurrentUser(include)

      if (err) {
        // Logout

        this.logout()
        this.$router.push({
          name: 'Home',
        })
      }

      this.populateTeamMeta({ include: ['gifting', 'rewards'] })
      this.user = { ...this.user, ...user }
      appStore.populateMetadata()
    },
    setUser(token) {
      window.localStorage.setItem('hithrive_user', token)
      apiClient.accessToken = token
      this.user = getAuthedUser()
      apiClient.teamSlug = this.user.team_slug
      this.populateUser()
    },
    logout() {
      window.localStorage.removeItem('hithrive_user')
      apiClient.accessToken = null
      apiClient.teamSlug = null
      this.user = null
      this.team = null
    },
    increaseRedeemablePoints(amount) {
      this.user.redeemable_points += amount
    },
    decreaseRedeemablePoints(amount) {
      this.user.redeemable_points -= amount
    },
  },
})
