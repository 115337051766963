<script setup>
import { useModal } from '@/modal'
import { ref } from 'vue'

const props = defineProps({
  data: Object,
})

const { openModal } = useModal()

const claim = ref(props.data)

const viewClaim = async () => {
  await openModal(import('@/components/admin/modals/ChallengeClaim.vue'), {
    claim: claim.value,
    challenge: claim.value.challenge_id,
  })
}
</script>
<template>
  <span
    ><strong>{{ claim.user_id.first_name }}</strong> claimed the
    <a href="#" @click.prevent="viewClaim">{{ claim.challenge_id.title }}</a>
    challenge.</span
  >
</template>
