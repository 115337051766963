<!-- eslint-disable no-unused-vars -->
<script setup>
import { useLocaleFormatter } from '@/utils'
import { ref } from 'vue'

const props = defineProps({
  data: Object,
  user: Object,
})

const ledgerEntry = ref(props.data)
const user = ref(props.user)

const { formatPoints, formatNumber } = useLocaleFormatter()

const innerHTML = (entry) => {
  const finalBalance = entry.balance

  let deltaDirection = 'made no change'

  const increase = entry.credit_amount || 0
  const decrease = entry.debit_amount || 0
  const settle = entry.settle_amount || 0

  const delta = increase > 0 ? increase : decrease * -1

  if (delta > 0) deltaDirection = 'increased'
  if (delta < 0) deltaDirection = 'decreased'

  const originalAmount = `<strong>${formatNumber(
    finalBalance + delta * -1
  )}</strong>`

  const finalBalanceAmount = `<strong>${formatNumber(finalBalance)}</strong>`

  const increaseAmount = `<strong>${formatNumber(increase)}</strong>`
  const decreaseAmount = `<strong>${formatNumber(decrease)}</strong>`

  const deltaAmount = `<strong>${formatPoints(Math.abs(delta))}</strong>`
  const settleAmount = `<strong>${formatPoints(Math.abs(settle))}</strong>`

  const forUser = `<strong>${user.value.first_name}'s allowance</strong>`
  const actor = `<strong>${entry.user_id.name.full_name}</strong>`

  const twoPartyPrefix = `${actor} ${deltaDirection} ${forUser} by ${deltaAmount} from ${originalAmount} to ${finalBalanceAmount}`
  const singlePartyPrefix =
    settle > 0
      ? `${forUser} was adjusted to ${settleAmount}`
      : `${forUser} ${deltaDirection} by ${deltaAmount} from ${originalAmount} to ${finalBalanceAmount}`

  const getSpan = (prefix, action) => {
    const suffix = action ? ` after ${action}` : ''
    return `${prefix} ${suffix}.`
  }

  switch (entry.reason) {
    case 'INTERVAL_RESET':
      return getSpan(singlePartyPrefix, 'an allowance interval reset')
    case 'ADMIN_ADJUSTMENT':
      return getSpan(twoPartyPrefix, 'sending a shoutout')
    case 'SENT_SHOUTOUT':
      return getSpan(singlePartyPrefix, 'sending a shoutout')
    case 'SHOUTOUT_DELETED':
      return getSpan(twoPartyPrefix, 'after deleting a shoutout')

    default:
      return getSpan(singlePartyPrefix)
  }
}
</script>
<template>
  <span class="user-activity-item" v-html="innerHTML(ledgerEntry)"></span>
</template>
