<script setup>
import { DateTime } from 'luxon'
import { useLocaleFormatter } from '@/utils'
import { readonly } from 'vue'

const { humanizedDateDiff } = useLocaleFormatter()

const props = defineProps({
  post: Object,
  hideDropdown: { type: Boolean, default: false },
})

const post = readonly(props.post)
console.log(post)
</script>

<template>
  <div class="post-meta">
    <span class="timestamp">{{
      humanizedDateDiff(DateTime.fromISO(post.created))
    }}</span>

    <button
      v-if="!hideDropdown"
      class="btn btn-circle-sm btn-context post-meta-dropdown-toggle"
    >
      <i class="fas fa-ellipsis-vertical"></i>
    </button>
  </div>
</template>
