<script setup>
import {
  readonly,
  markRaw,
  defineAsyncComponent,
  reactive,
  watchEffect,
} from 'vue'

const emit = defineEmits(['update:modelValue', 'validate'])
const props = defineProps({ fields: Array, modelValue: Object })

const fieldComponents = {
  textfield: defineAsyncComponent({
    loader: () => import('@/components/client/formViewer/TextField.vue'),
  }),
  textarea: defineAsyncComponent({
    loader: () => import('@/components/client/formViewer/TextArea.vue'),
  }),
  checkbox: defineAsyncComponent({
    loader: () => import('@/components/client/formViewer/CheckboxField.vue'),
  }),
  select: defineAsyncComponent({
    loader: () => import('@/components/client/formViewer/SelectField.vue'),
  }),
  file: defineAsyncComponent({
    loader: () => import('@/components/client/formViewer/FileField.vue'),
  }),
}

const fields = readonly(
  props.fields.map((field) => ({
    ...field,
    component: markRaw(fieldComponents[field.type]),
  }))
)

let form = reactive({})
const validations = reactive({})

watchEffect(() => {
  emit(
    'validate',
    Object.values(validations).every((v) => v)
  )
})

watchEffect(() => {
  form = props.modelValue ? reactive(props.modelValue) : form
})

watchEffect(() => {
  emit('update:modelValue', form)
})
</script>

<template>
  <form action="#" @submit.prevent>
    <component
      :is="field.component"
      v-for="field in fields"
      :key="`field_${field.id || field._tempId}`"
      v-model="form[field.id || field._tempId]"
      :field="field"
      @validate="validations[field.id] = $event"
    />
  </form>
</template>

<style lang="scss" scoped>
form {
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: max-content;
  row-gap: 15px;
}
</style>
