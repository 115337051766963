import { defineStore } from 'pinia'
import { AdminUserSegments, AdminTeam } from '@/api'

export const useAdminStore = defineStore('admin', {
  state: () => ({
    userSegments: null,
    customFields: null,
  }),
  getters: {
    getSegmentById: (state) => (segmentId) =>
      state.userSegments?.find(({ id }) => id === segmentId),
  },
  actions: {
    async populateUserSegments() {
      const [, segments] = await AdminUserSegments.listSegments()
      this.userSegments = [...segments].sort((a, b) =>
        a.name.localeCompare(b.name)
      )
    },

    async populateCustomFields() {
      if (this.customFields !== null) return

      const [, resp] = await AdminTeam.getCustomFields()
      this.customFields = resp
    },
  },
})
