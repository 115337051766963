<script setup>
const emit = defineEmits(['modalClose']);

function close() {
  emit('modalClose');
}
</script>
<template>
  <aside class="ht-modal">
    <div class="ht-dialog">
      <main>
        <h4>Unable to show this view</h4>
        <p>An error has prevented this view from being shown. Try again after a few seconds. If the error continues, please contact support.</p>

        <button @click.prevent="close">Close</button>
      </main>
    </div>
  </aside>
</template>

<style lang="scss" scoped>
.ht-dialog {
  padding: 15px !important;

  h4 {
    font-family: $font-family-sans-serif;
    font-weight: 600;
  }
}
</style>