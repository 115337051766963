<script setup>
import { computed } from 'vue'
import apiClient from '@/api'

const { teamSlug } = apiClient

const { VITE_API_BASE_URL } = import.meta.env

const props = defineProps({ user: Object })

const initials = computed(() =>
  props.user.first_name
    ? `${props.user.first_name.substring(0, 1)}${props.user.last_name.substring(
        0,
        1
      )}`
    : ''
)
</script>

<template>
  <div class="user--profile-picture" :style="{ '--initials': `'${initials}'` }">
    <img
      :src="`${VITE_API_BASE_URL}/${teamSlug}/users/${props.user.id}/profilePicture`"
      alt=""
    />
    <slot></slot>
  </div>
</template>
