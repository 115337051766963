import { defineAsyncComponent, markRaw } from 'vue'
import ErrorModal from '@/components/modals/LoadingError.vue'
import { useMakeID } from '@/utils'
import { useModalStore } from './store'
import { useProgressBar } from './nprogress'

const progressBar = useProgressBar()

export function useModal() {
  const modalStore = useModalStore()
  const { makeID } = useMakeID()

  const closeTopModal = () => {
    modalStore.$patch((state) => {
      state.stack.shift()
    })
  }

  const openModal = (
    componentLoader,
    data = {},
    closeValidator = async () => true
  ) =>
    new Promise((resolve) => {
      modalStore.$patch((state) => {
        state.stack.unshift({
          id: makeID(),
          show: false,
          component: markRaw(
            componentLoader instanceof Promise
              ? defineAsyncComponent({
                  loader: async () => {
                    progressBar.start()
                    const comp = await componentLoader
                    progressBar.done()
                    return comp
                  },
                  errorComponent: ErrorModal,
                })
              : componentLoader
          ),
          data,
          callback: (resp) => {
            closeTopModal()
            resolve(resp)
          },
          closeValidator,
          // eslint-disable-next-line func-names, object-shorthand
          triggerClose: async function () {
            const closeValidation = await this.closeValidator()
            if (closeValidation === false) return

            closeTopModal()
            resolve(false)
          },
        })
      })
    })

  const closeAllModals = async () => {
    if (modalStore.stack.length === 0) return true
    // eslint-disable-next-line no-restricted-syntax
    for (const modal of [...modalStore.stack]) {
      // eslint-disable-next-line no-await-in-loop
      await modal.triggerClose()
    }

    return false
  }

  return { openModal, closeAllModals }
}
