<script setup>
import { createPopper } from '@popperjs/core'
import { onMounted, ref } from 'vue'

const metaPopover = ref(null)
const popoverVisible = ref(false)
let popper

const currentReactionBar = {
  getBoundingClientRect: () => ({
    width: 0,
    height: 0,
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  }),
}

const hidePopover = () => {
  currentReactionBar.getBoundingClientRect = () => ({
    width: 0,
    height: 0,
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  })

  popoverVisible.value = false

  popper.update()
}

const showPopover = (target) => {
  popoverVisible.value = true

  currentReactionBar.getBoundingClientRect = () =>
    target.getBoundingClientRect()

  popper.update()
}

const handleMouseHover = (e) => {
  const { target } = e

  if (target.classList.contains('post-meta-dropdown-toggle'))
    showPopover(target)
  else hidePopover()
}

onMounted(() => {
  document.querySelector('html').addEventListener('click', handleMouseHover)

  popper = createPopper(currentReactionBar, metaPopover.value, {
    placement: 'left-start',
    strategy: 'fixed',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [5, -5],
        },
      },
    ],
  })
})
</script>

<template>
  <div
    ref="metaPopover"
    :class="[
      'reaction-dropdown',
      { 'reaction-dropdown--hidden': !popoverVisible },
    ]"
  >
    No options
  </div>
</template>

<style lang="scss" scoped>
.reaction-dropdown {
  display: block;
  position: fixed;
  background: #fff;
  border-radius: $border-radius;
  box-shadow: rgba(0, 0, 0, 8%) 0 1px 1px 0, $border-color 0 0 0 1px,
    rgba(0, 0, 0, 0.05) 0 12px 16px;
  min-width: 200px;
  min-height: 50px;
  z-index: 99;
  padding: 15px;

  &--hidden {
    visibility: hidden;
    pointer-events: none;
    z-index: -1;
  }

  > ul {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    padding: 0;
    column-gap: 5px;
    row-gap: 5px;
    margin: 0;

    > li {
      display: block;
      padding: 0;
      margin: 0;

      > a {
        display: block;
        font-size: 26px;
        text-decoration: none;
        width: 40px;
        height: 40px;
        line-height: 40px;
        border-radius: 12px;
        text-align: center;

        &:hover {
          background: $gray-200;
        }
      }
    }
  }
}
</style>
