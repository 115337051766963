import { defineStore } from 'pinia'
import { App } from '@/api'

// eslint-disable-next-line import/no-cycle
import { useUserStore } from './userStore'

export const useAppStore = defineStore('app', {
  state: () => ({
    metadata: null,
    homeComposeShoutoutTo: null,
    shippingAddressId: localStorage.getItem('hithrive_shipping_addr') ?? null,
  }),
  getters: {
    shippingAddress: (state) => {
      const userStore = useUserStore()

      const addresses = userStore.user.shipping_addresses
      let selectedAddr = addresses.find((a) => a.id === state.shippingAddressId)

      if (!selectedAddr) {
        ;[selectedAddr] = addresses

        if (!selectedAddr) return null

        state.shippingAddressId = selectedAddr.id
        localStorage.setItem('hithrive_shipping_addr', selectedAddr.id)
      }

      return selectedAddr
    },
    currencyRates: (state) => {
      const userStore = useUserStore()
      return state.metadata.rewardsService?.currencyRates[
        userStore.team.base_currency
      ]
    },
  },
  actions: {
    selectShippingAddr(addrId) {
      localStorage.setItem('hithrive_shipping_addr', addrId)
      this.shippingAddressId = addrId
    },
    async populateMetadata() {
      const [, data] = await App.getMetadata()

      this.metadata = data
    },
    openShoutoutComposer(to) {
      this.homeComposeShoutoutTo = to

      this.$router.push({ name: 'Home' })
    },
  },
})
