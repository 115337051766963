<script setup>
import { storeToRefs } from 'pinia'
import { useAdminStore } from '@/store'
import { ref, watch } from 'vue'

const props = defineProps({
  modelValue: {
    type: Array,
    default: () => [],
  },
  disableConditional: { type: Boolean, default: () => false },
})
const emit = defineEmits(['update:modelValue'])

const adminStore = useAdminStore()
const { userSegments } = storeToRefs(adminStore)

const selectedSegments = ref([])

watch(selectedSegments, (val) => {
  emit('update:modelValue', val)
})

watch(
  () => props.modelValue,
  (val) => {
    selectedSegments.value = val
  },
  { immediate: true }
)

adminStore.populateUserSegments()
</script>

<template>
  <div class="filter-list-selector">
    <template v-if="userSegments !== null">
      <ol>
        <li
          v-for="segment in userSegments"
          :key="`segmentListSelector_${segment.id}`"
        >
          <input
            :id="`slsCheck_${segment.id}`"
            v-model="selectedSegments"
            type="checkbox"
            class="form-check-input"
            :value="segment.id"
            :disabled="
              props.disableConditional === true &&
              segment.sync_by === 'conditions'
            "
          />
          <label :for="`slsCheck_${segment.id}`">
            <strong>{{ segment.name }}</strong>
            <small
              >{{ segment.member_count }} member{{
                segment.member_count === 1 ? '' : 's'
              }}</small
            >
          </label>
        </li>
      </ol>
    </template>
  </div>
</template>

<style lang="scss" scoped>
ol {
  > li {
    > label {
      line-height: 1.2em;
      font-size: 14px;

      > strong {
        display: block;
      }

      > small {
        display: block;
        color: $muted-text;
      }
    }
  }
}
</style>
