<script setup>
import { DateTime } from 'luxon'
import { toHTML } from 'slack-markdown'
import { isEmpty } from 'lodash-es'
import { computed, shallowReactive } from 'vue'
import { useUserStore } from '@/store'
import { storeToRefs } from 'pinia'
import { useLocaleFormatter } from '@/utils'
import { useModal } from '@/modal'
import ChallengeModal from '@/components/client/modals/ChallengeDetails.vue'

const { formatPoints } = useLocaleFormatter()
const { openModal } = useModal()
const emit = defineEmits(['claimMade'])
const userStore = useUserStore()
const { team } = storeToRefs(userStore)

const props = defineProps({ challenge: Object, previewMode: Boolean })
const challenge = shallowReactive(props.challenge)

const claimStatus = computed(() => {
  switch (challenge.claim.status) {
    case 'PENDING':
      return `Reviewing Claim`

    case 'REJECTED':
      return `Claim Rejected`

    default:
      return ''
  }
})

const endTime = (
  challenge.end_date instanceof Date
    ? DateTime.fromJSDate(challenge.end_date, { zone: 'utc' })
    : DateTime.fromISO(challenge.end_date, { zone: 'utc' })
).setZone(team.value.timezone, { keepLocalTime: true })

async function openChallengeModal() {
  const resp = await openModal(ChallengeModal, {
    challenge: props.challenge,
    previewMode: props.previewMode,
  })
  emit('claimMade', resp.entityData)
}
</script>

<template>
  <a href="#" class="challenge-card" @click.prevent="openChallengeModal">
    <div class="banner">
      <div
        v-if="challenge.claim"
        :class="[
          'claim-status',
          `claim-status--${challenge.claim.status.toLowerCase()}`,
        ]"
      >
        {{ claimStatus }}
      </div>
      <img
        :src="`${challenge.banner_image.src}&w=640&h=275&fit=crop&crop=focalpoint`"
      />
    </div>

    <div class="content">
      <h4>{{ challenge.title }}</h4>
      <div class="description" v-html="toHTML(challenge.description)"></div>
      <div class="details">
        <span v-if="challenge.points && challenge.points > 0" class="points"
          >+{{ formatPoints(challenge.points) }}</span
        >
        <span v-else-if="!isEmpty(challenge.gift_options)" class="gift"
          ><i class="fa-solid fa-gift"></i
        ></span>

        <span v-if="challenge.end_date" class="end-time">{{
          endTime.diffNow().as('days') > 3
            ? `Ends on ${endTime.toLocaleString(DateTime.DATE_MED)}`
            : `Ends ${endTime.toRelative()}`
        }}</span>
      </div>
    </div>
  </a>
</template>

<style lang="scss" scoped>
.challenge-card {
  @include clickable-card;
  overflow: hidden;
  color: $black;

  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: max-content 1fr;

  > .banner {
    margin: -20px -20px 0;
    position: relative;

    > .claim-status {
      border-radius: 100em;
      background: #fff;
      position: absolute;
      font-weight: 600;
      letter-spacing: 1px;
      font-size: 10px;
      text-transform: uppercase;
      padding: 0 7px;
      line-height: 16px;
      top: 10px;
      left: 10px;

      &--pending {
        background: $secondary;
        color: #fff;
      }

      &--rejected {
        background: $danger;
        color: #fff;
      }
    }

    > img {
      width: 100%;
      height: auto;
    }
  }

  > .content {
    background: #fff;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    position: relative;
    padding: 20px 20px 0;
    margin: -8px -20px 0;
    display: flex;
    flex-direction: column;

    > h4 {
      font-weight: 600;
      font-size: 18px;
    }

    > .description {
      margin: 0 0 1rem;
      // stylelint-disable-next-line
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 14px;
    }

    > .details {
      margin: auto 0 0;
      display: flex;
      column-gap: 10px;
      align-items: center;

      > span {
        display: block;
        color: $muted-text;

        &.points {
          background: $green;
          color: #fff;
          font-weight: 600;
          line-height: 20px;
          font-size: 11px;
          padding: 0 5px;
          border-radius: 3px;
        }

        &.gift {
          background: $secondary;
          color: #fff;
          font-weight: 600;
          line-height: 20px;
          font-size: 11px;
          padding: 0 5px;
          border-radius: 3px;
        }
      }
    }
  }
}
</style>
