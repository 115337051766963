<script setup>
import { Post } from '@/api'
import { computed, ref } from 'vue'
import { nameToEmoji } from 'gemoji'

const props = defineProps({
  entityId: String,
  readOnly: {
    type: Boolean,
    default: () => false,
  },
})

const entityId = ref(props.entityId)

const reactions = ref(null)
let myReactions = []

// Get reactions from API
async function populateReactions() {
  const [err, resp] = await Post.getReactions({ entityId: entityId.value })

  if (err) return

  reactions.value = resp.reactions
  myReactions = resp.my_reactions
}

populateReactions()

async function toggleReaction(_emoji) {
  const emoji = _emoji.toString()
  // See if we've already reacted this
  Post.react({ entityId: entityId.value, reaction: emoji })

  if (myReactions.includes(emoji)) {
    // remove
    myReactions.splice(
      myReactions.findIndex((r) => r === emoji),
      1
    )

    reactions.value[emoji] -= 1
  } else {
    myReactions.push(emoji)

    if (reactions.value[emoji] === undefined) reactions.value[emoji] = 0
    reactions.value[emoji] += 1
  }

  Object.entries(reactions.value).forEach(([k, count]) => {
    if (count === 0) delete reactions.value[k]
  })
}

function handleReaction(e) {
  const reaction = e.detail.emoji

  // Toggle reaction
  toggleReaction(reaction)
}

const hasContent = computed(
  () =>
    (reactions.value !== null && Object.keys(reactions.value).length > 0) ||
    props.readOnly !== true
)
</script>

<template>
  <div v-if="hasContent" class="reaction-bar">
    <ul>
      <template v-if="reactions !== null">
        <li
          v-for="[reaction, count] in Object.entries(reactions)"
          :key="`reactions_${entityId}_${reaction}`"
          :class="{ 'my-reaction': myReactions.includes(reaction) }"
        >
          <a href="#" @click.prevent="toggleReaction(reaction)"
            ><span v-html="nameToEmoji[reaction]"></span
            ><strong>{{ count }}</strong></a
          >
        </li>
      </template>
      <li v-if="readOnly !== true" class="add-reaction">
        <a href="#" class="reaction-bar-add-reaction" @react="handleReaction"
          ><i class="far fa-heart"></i
        ></a>
      </li>
    </ul>
  </div>
</template>

<style lang="scss" scoped>
.reaction-bar {
  > ul {
    display: flex;
    padding: 0;
    margin: 0 -5px;

    > li {
      display: block;
      padding: 0 5px;

      > a {
        color: $body-color;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 34px;
        font-weight: 700;
        box-shadow:
          rgb(0 0 0 / 12%) 0 1px 1px 0,
          $border-color 0 0 0 1px,
          $border-color 0 2px 5px 0;
        background: #fff;
        border-radius: 100em;
        font-size: 12px;
        line-height: 34px;
        min-width: 34px;
        text-align: center;
        text-decoration: none;
        padding: 0;
        column-gap: 2px;

        > span {
          font-size: 20px;
          display: block;
          transition: transform 0.2s $curve;
          margin: 0 0 0 7px;
        }

        > strong {
          font-size: 12px;
          display: block;
          padding: 0 13px 0 5px;
        }

        &:hover {
          > span {
            transform: scale(1.2);
          }
        }
      }

      &.my-reaction {
        > a {
          background: color.adjust($primary, $lightness: 72%);
        }
      }

      &.add-reaction {
        > a {
          box-shadow: $border-color 0 0 0 1px;
          color: $muted-text;
          background: $light;
          font-size: 16px;

          &:hover {
            background: $gray-200;
          }

          > i {
            pointer-events: none;
          }
        }
      }
    }
  }
}
</style>
