<script setup>
import { createPopper } from '@popperjs/core'
import { onMounted, ref } from 'vue'
import { debounce } from 'lodash-es'

const profilePopover = ref(null)
const hoveredUser = ref(null)
let popper

const currentUserMention = {
  getBoundingClientRect: () => ({
    width: 0,
    height: 0,
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  }),
}
const debounced = debounce((e, domPath) => {
  const { target } = e
  const hidePopover = () => {
    currentUserMention.getBoundingClientRect = () => ({
      width: 0,
      height: 0,
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    })
    hoveredUser.value = null

    popper.update()
  }

  const showPopover = () => {
    hoveredUser.value = target.$HiThriveUser

    currentUserMention.getBoundingClientRect = () =>
      target.getBoundingClientRect()

    popper.update()
  }

  if (domPath.some((elem) => elem === profilePopover.value)) debounced.cancel()
  else if (target.classList.contains('user-mention')) showPopover()
  else hidePopover()
}, 250)

const handleMouseHover = (event) => {
  debounced(event, event.composedPath())
}

onMounted(() => {
  document.querySelector('html').addEventListener('mouseover', handleMouseHover)

  popper = createPopper(currentUserMention, profilePopover.value, {
    placement: 'right-start',
    strategy: 'fixed',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 10],
        },
      },
    ],
  })
})
</script>

<template>
  <div
    ref="profilePopover"
    :class="[
      'profile-popover',
      { 'profile-popover--hidden': hoveredUser === null },
    ]"
  >
    <template v-if="hoveredUser">
      <user-card :user="hoveredUser" />
    </template>
  </div>
</template>

<style lang="scss" scoped>
.profile-popover {
  display: block;
  position: fixed;
  background: #fff;
  border-radius: $border-radius;
  box-shadow: rgba(0, 0, 0, 8%) 0 1px 1px 0, $border-color 0 0 0 1px,
    rgba(0, 0, 0, 0.05) 0 12px 16px;
  min-width: 200px;
  min-height: 50px;
  z-index: 99;
  padding: 15px;

  &--hidden {
    visibility: hidden;
    pointer-events: none;
    z-index: -1;
  }
}
</style>
