<script setup>
import { computed } from 'vue'

const props = defineProps({
  color: String,
})

const color = computed(() => {
  switch (props.color) {
    case 'dark':
      return '#7c7979'
    default:
      return '#ffffff'
  }
})
</script>

<template>
  <svg
    class="loading-spinner"
    width="20px"
    height="20px"
    viewBox="0 0 66 66"
    xmlns="http://www.w3.org/2000/svg"
    :style="{ stroke: color }"
  >
    <circle
      class="circle"
      fill="none"
      stroke-width="6"
      stroke-linecap="round"
      cx="33"
      cy="33"
      r="30"
    ></circle>
  </svg>
</template>
