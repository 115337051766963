<script setup>
import { Post } from '@/api'
import { computed, nextTick, ref, watch } from 'vue'
import { useUserStore } from '@/store'
import { storeToRefs } from 'pinia'
import ProfilePicture from '@/components/userCard/ProfilePicture.vue'
import { useToast } from 'vue-toastification'
import { useLocaleFormatter } from '@/utils'
import { DateTime } from 'luxon'

const { humanizedDateDiff } = useLocaleFormatter()

const toast = useToast()

const composing = ref(false)
const commentField = ref(null)
const posting = ref(false)

watch(composing, async (val) => {
  await nextTick()

  if (val === true) {
    commentField.value.focus()
  }
})

const userStore = useUserStore()
const { user: currentUser } = storeToRefs(userStore)

const props = defineProps({
  entityId: String,
  readOnly: Boolean,
})

const entityId = ref(props.entityId)

const comments = ref(null)
const nextPage = ref(null)
const loading = ref(false)

// Get reactions from API
async function populateComments() {
  loading.value = true
  const [err, resp] = await Post.getComments({
    entityId: entityId.value,
    cursor: nextPage.value,
    limit: nextPage.value ? 5 : 3,
  })
  loading.value = false

  if (err) return

  if (comments.value === null) comments.value = resp.comments
  else comments.value.push(...resp.comments)

  if (resp.commentCursor.moreResults === 'NO_MORE_RESULTS')
    nextPage.value = null
  else nextPage.value = resp.commentCursor.endCursor
}

populateComments()

async function postComment() {
  const val = commentField.value.innerText
  if (val.trim().length === 0) return

  posting.value = true
  const [err] = await Post.comment({ entityId: entityId.value, comment: val })
  posting.value = false

  if (err) {
    toast.error(`Your comment couldn't be posted. Please try again.`)
    return
  }

  composing.value = false
  commentField.value.innerText = ''

  comments.value.push({
    comment: val,
    user: currentUser.value,
    created_at: new Date().toISOString(),
  })
}

function handleCommentInput(e) {
  if (e.keyCode === 13 && e.shiftKey === false) {
    e.preventDefault()

    // Send comment
    postComment()
  }
}

const hasContent = computed(
  () =>
    (comments.value !== null && comments.value.length > 0) ||
    props.readOnly !== true
)
</script>

<template>
  <div v-if="hasContent" class="comment-bar">
    <template v-if="comments !== null && comments.length > 0">
      <ol class="comments">
        <li
          v-for="comment in comments"
          :key="`comment_${entityId}_${comment.id}`"
        >
          <profile-picture :user="comment.user" />
          <div class="comment-wrap">
            <div class="comment-header">
              <strong>{{ comment.user.full_name }}</strong>

              <span class="timestamp">
                {{ humanizedDateDiff(DateTime.fromISO(comment.created_at)) }}
              </span>
            </div>
            {{ comment.comment }}
          </div>
        </li>
      </ol>
      <div v-if="nextPage" class="has-more-comments">
        <a
          href="#"
          :class="{ loading: loading }"
          @click.prevent="populateComments"
          >{{ loading ? `Loading comments...` : `View more comments` }}</a
        >
      </div>
    </template>

    <div
      v-if="readOnly !== true"
      :class="['comment-composer-wrap', { composing }]"
    >
      <profile-picture :user="currentUser" />
      <div v-if="!composing" class="preview" @click.prevent="composing = true">
        <span>Write a comment...</span>
      </div>
      <div v-else :class="['comment-input-wrap', { posting }]">
        <div
          ref="commentField"
          class="comment-composer"
          contenteditable="true"
          @keydown="handleCommentInput"
        ></div>

        <div class="actions">
          <button
            class="btn btn-circle btn-outline-secondary no-outline"
            @click.prevent="postComment"
          >
            <i class="far fa-send"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.comment-bar {
  > .has-more-comments {
    margin: -10px 0 20px;
    padding: 0 0 0 42px;

    > a {
      font-size: 12px;
      font-weight: 600;

      &.loading {
        opacity: 0.5;
        pointer-events: none;
      }
    }
  }

  > .comments {
    display: grid;
    grid-template-columns: 1fr;
    padding: 0;
    margin: 0 0 20px;
    row-gap: 10px;

    > li {
      display: grid;
      grid-template-columns: 32px max-content;
      align-items: flex-start;
      column-gap: 8px;

      > .user--profile-picture {
        width: 32px;
      }

      > .comment-wrap {
        border-radius: 8px;
        box-shadow:
          $border-color 0 0 0 1px,
          rgba(0, 0, 0, 0.05) 0 1px 3px 0;
        padding: 12px;
        font-size: 0.85rem;

        > .comment-header {
          display: grid;
          grid-template-columns: 1fr max-content;
          white-space: nowrap;
          column-gap: 35px;
          align-items: center;

          > strong {
            display: block;
            margin: 0 0 0.1rem;
            line-height: 1rem;
          }

          > .timestamp {
            display: block;
            font-size: 10px;
            color: $muted-text;
          }
        }
      }
    }
  }

  > .comment-composer-wrap {
    display: flex;
    align-items: flex-start;
    flex-wrap: nowrap;
    column-gap: 6px;

    > .user--profile-picture {
      width: 32px;
      margin: 2px 0 0;
    }

    > .preview {
      flex: 1 0;
      padding: 0 15px;
      height: 36px;
      font-size: 14px;
      line-height: 36px;
      background: $gray-100;
      box-shadow:
        $border-color 0 0 0 1px,
        rgba(0, 0, 0, 0.05) 0 1px 2px;
      border-radius: 100em;
      cursor: pointer;
      color: #5c5959;

      &:hover {
        background: $gray-200;
        box-shadow: $border-color 0 0 0 1px;
      }
    }

    > .comment-input-wrap {
      background: $gray-100;
      border-radius: 20px;
      flex: 1 0;
      padding: 0;
      box-shadow: $border-color 0 0 0 1px;

      &.posting {
        pointer-events: none;
        opacity: 0.8;
      }

      > .actions {
        display: flex;
        justify-content: flex-end;
        padding: 6px;

        .btn-circle {
          width: 30px;
          height: 30px;
          font-size: 1em;

          &:hover {
            > i {
              font-weight: 700;
            }
          }
        }
      }

      > .comment-composer {
        min-height: 16px;
        outline: none;
        font-size: 14px;
        padding: 8px 15px;

        &:empty {
          &::before {
            content: 'Write a comment...';
            color: #5c5959;
            pointer-events: none;
          }
        }
      }
    }
  }
}
</style>
