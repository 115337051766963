<template>
  <div class="flowerpot-loader"></div>
</template>

<style lang="scss" scoped>
$aspect-ratio: math.div(546.1, 195);
$width: 76px;
$height: $width * $aspect-ratio;

.flowerpot-loader {
  background-image: url('@/assets/loading-sprite.webp');
  background-position: 0 0;
  background-size: 100% auto;
  width: $width;
  height: $height;
  opacity: 0;
  animation: init 0ms forwards 150ms,
    flowerpotLoader 2000ms infinite steps(30, jump-none);
  margin: 0 auto;
}

@keyframes init {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes flowerpotLoader {
  to {
    background-position: 0 ($height * 29);
  }
}
</style>
