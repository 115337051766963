<script setup>
import { ref } from 'vue'
import { AdminTeam } from '@/api'
import { storeToRefs } from 'pinia'
import { useUserStore } from '@/store'

const userStore = useUserStore()

const { team } = storeToRefs(userStore)

const tosRead = ref(false)
const handleTOSScroll = ({
  target: { scrollTop, clientHeight, scrollHeight },
}) => {
  if (scrollTop + clientHeight + 80 >= scrollHeight) {
    tosRead.value = true
  }
}

const tosToggle = ref(false)
const loadingTOS = ref(false)

const handleAcceptTOS = async () => {
  loadingTOS.value = true
  await AdminTeam.acceptTOS()
  loadingTOS.value = false
  team.value.accepted_tos = true
}
</script>

<template>
  <div v-if="team && !team.accepted_tos">
    <div id="modal-stack">
      <div
        class="ht-modal"
        :style="{
          'z-index': 9999,
          '--modalIndex': index,
        }"
      >
        <div id="tos" class="ht-dialog">
          <main @scroll="handleTOSScroll">
            <h1>Welcome to HiThrive</h1>
            <strong>Please review our terms of service to continue</strong>
            <div class="legal-doc_content-top">
              <div class="legal-doc_date-updated-wrap">
                <div class="text-weight-semibold">Last updated</div>
                <div class="legal-doc_date-updated">
                  <div class="text-size-small">April 20, 2023</div>
                </div>
              </div>
            </div>

            <p>
              These Terms of Service (the <strong>"Terms")</strong> constitute
              an agreement governing the use of the Service (defined below)
              between our Customer and HiThrive Inc., the provider of the
              Service (which we refer to in these Terms as "HiThrive," "we" or
              "us"). Our "Customer" is either (i) the initial individual
              subscriber for the Service who invites other individuals to join
              the initial subscriber's group to use the Service, where that
              group of individuals is not part of the same business or
              organization, or (ii) a business or organization, where an
              individual initiates a subscription for the Service on behalf of
              that business or organization, providing an email address using
              the domain of such business or organization, or (iii) the
              organization identified in the applicable Order Form. When we
              refer to "you," "your," and "yours" in these Terms, we are
              referring to the Customer. References to the
              <strong>"Service"</strong> refer to the online software as a
              service (<strong>"Software"</strong>) subscribed to by you and
              made available by us and supporting services, as described in the
              Order Form or otherwise made available by us. For the resolution
              of doubt, "Service" includes implementation and configuration
              services, Help Center and Library information and resources, and
              Customer Care and support services, as applicable. We and you are
              each sometimes referred to herein as a
              <strong> "party." </strong>
            </p>
            <p>
              The individual who initiates a subscription for a Customer is
              considered the "Administrator" for the Customer. The Customer may
              elect to change the Administrator(s) from time to time. The
              Administrator and other individual(s) using the Service under a
              Customer's subscription are referred to in these Terms as a "User"
              or "Users." The initial Administrator represents and warrants to
              us, on behalf of the Customer, that such Administrator has the
              authority to bind the Customer to these Terms. By using the
              Service, the Customer acknowledges and agrees that it shall be
              legally bound by these Terms. The Administrator shall ensure that
              all Users are notified of these Terms, including the Use
              Restrictions set forth below. If the Customer does not agree to be
              bound to these Terms as a legal contract, the Customer must
              terminate its subscription and ensure that its Users cease use of
              the Service.
            </p>
            <p>
              By using the Service, the Customer and all of its Users also
              acknowledge and accept the provisions of our
              <a href="https://www.hithrive.com/privacy-policy">
                Privacy Policy
              </a>
              , which describes the information we obtain from you and your
              Users, and how we use it.
            </p>
            <p><strong>1.</strong> <strong>Accessing the Service</strong></p>
            <p>
              The Administrator has certain responsibilities on behalf of a
              Customer.
            </p>
            <p>1.1 Obtaining a Subscription</p>
            <p>
              The Administrator must obtain and maintain a subscription for the
              Customer and its Users to access and use the Service. A
              subscription may be procured through one of several alternatives:
              (i) through the HiThrive website, (ii) by completing and
              submitting to us an Order Form, or (iii) through your account with
              an integrated third-party service, such as Slack or Microsoft
              Teams (a "Third Party Service"). If you select one of the first
              two methods, the Administrator shall provide us with an email
              address and other information we require to establish a Customer
              subscription. If you choose the Third Party Service option, we'll
              create the Customer account by extracting from your Third Party
              Service certain information regarding you and your Users, such as
              name, email address and other information that the privacy
              settings on the Third Party Service permit us to obtain. We refer
              to this information we obtain from you and/or a Third Party
              Service as <strong> "Customer Information."</strong> Please
              contact us at support@hithrive.com for more information.
            </p>
            <p>
              You must ensure that the Customer Information is accurate and
              complete, and is updated in a timely manner when necessary. If the
              Customer Information is incorrect, we may not be able to contact
              you, and we reserve the right to suspend or terminate your
              subscription. If we provide the Administrator with a password for
              a Customer administrator account, it is the Customer's
              responsibility to establish and maintain security for such
              password. We reserve the right to not provide the Customer's
              password to anyone unless we are satisfied, in our sole
              discretion, that the individual requesting the password is
              authorized by the Customer. You must notify us immediately of any
              unauthorized access to or use of your password, or the Service.
              You are responsible for all activities that occur under your
              subscription, whether or not you know about or authorize them.
            </p>
            <p>
              A Customer subscription is completed when we accept the Customer
              request and make the Service available to the Customer and its
              Users, and shall remain in effect for the period of time specified
              in the Customer account settings or in the Order Form, as
              applicable, or until any earlier termination according to these
              Terms. You may be eligible to use the Service for an initial free
              trial subscription before purchasing a subscription. Please see
              our
              <a
                href="https://help.hithrive.com/hc/en-us/articles/360042755612"
              >
                Pricing Page
              </a>
              or applicable Order Form for information regarding subscription
              pricing.
            </p>
            <p>1.2 Eligibility</p>
            <p>
              The Administrator shall ensure that all Users are 18 years or
              older, and are authorized by the Customer to use the Service. The
              Service is not directed to minors, and we rely on Administrators
              to ensure minors do not use the Service. We do not knowingly
              collect personal information from minors and, if we learn that we
              have inadvertently obtained information in violation of applicable
              laws prohibiting collection of information from minors, we will
              promptly delete it.
            </p>
            <p>1.3 Compliance</p>
            <p>
              It is the Administrator's obligation to ensure that all Users
              comply with our <strong>Use Restrictions</strong>. In addition,
              the Administrator shall ensure that all Users comply with the
              Customer's policies and legal obligations, as we do not assume any
              responsibility for actions taken, or omissions by, Users when
              using the Service.
            </p>
            <p>
              <strong>2.</strong> <strong>Providing the Service to You</strong>
            </p>
            <p>2.1 Availability</p>
            <p>
              HiThrive will endeavor to make the Service available 24 hours a
              day, 7 days a week, excluding such periods when the Service may be
              unavailable due to necessary maintenance, updates or other
              factors, and unanticipated downtime. We anticipate that Service
              unavailability will be infrequent and of very limited duration and
              should we know that there will be any extended unavailability, we
              will use reasonable efforts to provide advance notice to you.
              However, HiThrive does not offer any guarantee or warranty that
              the Service will be available at all times, or that it will
              continue to be offered indefinitely.
            </p>
            <p>2.2 Service Providers</p>
            <p>
              HiThrive utilizes various skilled third parties (<strong
                >"Service Providers"</strong
              >) to provide technical or other services relating to all or part
              of our fulfillment of the Service, and you hereby agree that such
              involvement by these Service Providers is acceptable. Please see
              our
              <a href="https://www.hithrive.com/privacy-policy">
                Privacy Policy
              </a>
              to understand the extent to which any third-party may have access
              to Customer Data.
            </p>
            <p>2.3 Third Party Services</p>
            <p>
              The Service may allow you to connect to or otherwise interact with
              one or more third-party service providers (for example, a human
              resources information system) for purposes permitted by the
              Service. Because we do not control such third-party service
              providers, access to any such third-parties through the Service
              may be implemented, suspended or terminated by us from time to
              time in our sole discretion, including as may be necessary for
              security or maintenance purposes or as required by applicable law.
              It is your sole responsibility to enter into and maintain any
              agreement between you and any such third party for the provision
              of their services to you or otherwise, and we are not hereby made
              a party to such agreement. To the extent you or your
              representatives or Authorized Users use the Service to transmit
              any Customer Content (defined below) to or from any such third
              party, you direct and authorize us to provide or receive,
              respectively, such Customer Content to or from such third party.
              To the extent you or your representatives or Authorized Users use
              the Service to connect or otherwise interact with any such third
              party, or have identified or designated any such third party as
              your third-party service provider, you authorize us to allow such
              third party to access your Customer Content as necessary for us to
              provide the Service to you. You acknowledge and agree that such
              third parties are not our agents, that we are not responsible for
              their services, compliance, accuracy, actions or omissions or for
              their maintenance or treatment of your Customer Content, that we
              will not be liable for and specifically disclaim liability for any
              damage or loss caused thereby, that access to such third party via
              the Service does not imply any endorsement by us, and that any of
              your Customer Content submitted to such third parties via the
              Service will be governed by your agreement (if any) with such
              third party. We are not responsible for any disclosure,
              modification or deletion of Customer Content resulting from access
              by such third party.
            </p>
            <p>2.4 Updates</p>
            <p>
              We reserve the right to update, upgrade or otherwise modify the
              Software at any time and with or without notice; provided,
              however, we will provide advance notice of anticipated downtime
              when possible. We may suspend provision of the Software, at any
              time with reasonable notice (except in emergency cases relating to
              security or adverse impacts on the Service), for security or
              maintenance purposes or as required by applicable law. We will use
              commercially reasonable efforts to minimize the duration of any
              such suspension.
            </p>
            <p>
              While we expect to provide updates and other modifications to the
              Service from time to time, and guidance on how to use the Service
              as it may be updated, we shall not have any obligation to provide
              specific support to you with respect to the use or modifications
              of the Service.
            </p>
            <p>
              We may share information about future enhancements or other
              changes to the Service, but you should not rely upon them when
              deciding to subscribe. Your decision to subscribe should be based
              upon the functionality available when you are making that
              decision, and not on the potential future functionality.
            </p>
            <p><strong>3.</strong> <strong>Ownership</strong></p>
            <p>3.1 Company Properties</p>
            <p>
              As between you and us, the Service and all content therein
              (excluding Customer Content, defined below) (collectively, the
              <strong> "Company Properties" </strong>
              ), any all and all modifications, enhancements, upgrades and
              updates thereto, and all copyrights, trademarks, service marks,
              trade secrets, patents and other intellectual property rights
              therein (registered or unregistered), are the exclusive property
              of us and our suppliers. All rights in and to Company Properties
              not expressly granted to you in these Terms are reserved by us and
              our suppliers. For the avoidance of doubt, these Terms do not
              grant you any right to copy, transmit, transfer, modify or create
              derivative works of the Company Properties, or reverse engineer,
              reverse compile, reverse assemble or otherwise determine or derive
              source code of the Company Properties, or any other right in or to
              the Company Properties not specifically set forth herein.
            </p>
            <p>3.2 Customer Content</p>
            <p>
              As between you and us, you are the owner of the content,
              information, and other data (including, as applicable, any
              Confidential or personal information) uploaded by you to the
              Software, or otherwise transmitted by you or your representatives
              or third-party service providers in connection with your use of
              the Service (collectively,
              <strong> "Customer Content" </strong>
              ). You hereby grant us a non-exclusive, worldwide, royalty- free
              and fully paid license, during the Term, to use the Customer
              Content as necessary for the purpose of providing the Service to
              you. Notwithstanding the foregoing license, we have no obligation
              to process, render, prepare or produce the Customer Content in any
              manner or format other than as described in the Documentation. You
              are responsible for the accuracy, quality and legality of Customer
              Content, the means by which you acquired Customer Content, your
              use of Customer Content with the Service and your interoperation
              of any Customer Content with the Service.
            </p>
            <p>3.3 Protecting Customer Data</p>
            <p>
              Each party's performance hereunder will be in compliance with such
              party's privacy policy and all applicable privacy laws (including
              without limitation, as applicable, United States, Canada and
              European Economic Area), rules and regulations. Personal
              information transmitted, entered or otherwise uploaded by you and
              your Users to the Service will be processed in accordance with our
              Privacy Policy and all applicable laws. We will maintain
              appropriate administrative, technical, and procedural safeguards
              designed to protect the security, confidentiality and integrity of
              all Customer Content
              <strong>("Security Measures"</strong>). Our Security Measures will
              include, but will not be limited to, measures designed to prevent
              unauthorized access to or, disclosure of Customer Content (other
              than by you or your Authorized Users).
            </p>
            <p>3.4 License to Use Feedback</p>
            <p>
              You grant us a worldwide, perpetual, irrevocable, royalty-free
              license to use and incorporate into our Software and Service any
              suggestion, enhancement request, recommendation, correction or
              other feedback provided by you or your Authorized Users relating
              to the operation of the Company or the Service.
            </p>
            <p><strong>4.</strong> <strong>Term and Termination</strong></p>
            <p>4.1 Term</p>
            <p>
              The term of your subscription and rights to use the Service will
              terminate on the date set forth in your Order Form, if any. The
              <strong> "Effective Date" </strong>
              means the Effective Date specified on the Order Form or, if no
              date is specified, the date the Order Form was entered. The
              <strong> "Initial Subscription Term" </strong>
              means the Initial Service Term of the Software subscription
              specified in the Order Form or the period from the Effective Date
              through the expiration of the Initial Service Term. Unless sooner
              terminated as provided in this Section 4, these Terms will be
              effective beginning on the Effective Date and remain in effect for
              the combined duration of the Initial Subscription Term and any
              subsequent Service Term (each a <strong>"Renewal Term"</strong>),
              such that these Terms are coterminous with the natural expiration
              or earlier termination of the latest Order Form, as applicable.
              The Initial Subscription Term collectively with each Renewal Term
              is the "<strong>Term</strong>". Notwithstanding the foregoing, the
              maximum term of use for any complimentary Software product is the
              earlier of the renewal, expiration, or termination of a paid
              Software product included on the Order Form, provided that use of
              a complimentary (unpaid) Software product or Service may be
              terminated by us at any time and for any reason.
            </p>
            <p>4.2 Renewal</p>
            <p>
              Unless an Order Form provides otherwise, (i) all subscriptions
              automatically renew (without the need to go through the
              Service-interface "check-out" or execute a renewal Order Form) for
              an additional period equal to the preceding term; and (ii) subject
              to any price increase (see below), the per-User pricing during any
              automatic renewal term will remain the same as it was during the
              immediately prior term. You may notify us of your desire to not
              automatically renew by providing us with at least thirty (30)
              days' notice prior to the end of the subscription term to stop the
              subscription from automatically renewing; if you do so, your right
              to use the Service shall terminate at the end of the then-current
              subscription term.
            </p>
            <p>4.3 Termination for Breach</p>
            <p>
              Either party may terminate these Terms or any Order Form if the
              other party materially breaches these Terms or Order Form and
              fails to cure such breach within thirty (30) days after it
              receives written notice of such breach. A material breach
              includes, without limitation, a failure to make a full and timely
              payment (not disputed in good faith), or a commission of any of
              the acts contemplated in Section 5. If you terminate these Terms
              or an Order Form due to HiThrive's breach, any related prepaid
              Fees for Services not yet provided will be refunded to you.
            </p>
            <p>4.4 Effect of Termination</p>
            <p>
              Sections 3 (Ownership), 4 (Term and Termination), 6
              (Confidentiality), 7 (Payment of Fees) (with respect to Fees
              accrued prior to termination), 8 (Warranties and Disclaimer), 9
              (Indemnification), 10 (Limitation of Liability), 11 (Dispute
              Resolution), and 12 (General) will survive any termination of
              these Terms. Termination of these Terms with respect to one Order
              Form does not terminate these Terms with respect to any other
              Order Form.
            </p>
            <p><strong>5.</strong> <strong>Use Restrictions</strong></p>
            <p>
              You agree to ensure that Users shall not do any of the following:
            </p>
            <p>
              ● Post, upload, publish, submit or transmit any Customer Data
              that: (i) infringes, misappropriates or violates a third party's
              patent, copyright, trademark, trade secret, moral rights or other
              intellectual property rights, or rights of publicity or privacy;
              (ii) violates, or encourages any conduct that would violate, any
              applicable law or regulation or would give rise to civil
              liability; (iii) is fraudulent, false, misleading or deceptive;
              (iv) is defamatory, obscene, pornographic, vulgar or offensive;
              (v) promotes discrimination, bigotry, racism, hatred, harassment
              or harm against any individual or group; (vi) is violent or
              threatening or promotes violence or actions that are threatening
              to any person or entity; or (vii) promotes illegal or harmful
              activities or substances;
            </p>
            <p>
              ● Use, display, mirror or frame the Service or any individual
              element within the Service, HiThrive's name, any HiThrive
              trademark, logo or other proprietary information, or the layout
              and design of any page or form contained on a page, without
              HiThrive's express written consent;
            </p>
            <p>
              ● Access, tamper with, or use non-public areas of the Service,
              HiThrive's computer systems, or the technical delivery systems of
              HiThrive's providers;
            </p>
            <p>
              ● Attempt to probe, scan or test the vulnerability of any HiThrive
              system or network or breach any security or authentication
              measures;
            </p>
            <p>
              ● Avoid, bypass, remove, deactivate, impair, descramble or
              otherwise circumvent any technological measure implemented by
              HiThrive or any of HiThrive's providers or any other third party
              (including another user) to protect the Service or Customer Data;
            </p>
            <p>
              ● Attempt to access or search the Service or Customer Data or
              download Customer Data from the Service through the use of any
              engine, software, tool, agent, device or mechanism (including
              spiders, robots, crawlers, data mining tools or the like) other
              than the software and/or search agents provided by HiThrive or
              other generally available third-party web browsers;
            </p>
            <p>
              ● Send any unsolicited or unauthorized advertising, promotional
              materials, email, junk mail, spam, chain letters or other form of
              solicitation;
            </p>
            <p>
              ● Use any meta tags or other hidden text or metadata utilizing a
              HiThrive trademark, logo URL or product name without HiThrive's
              express written consent;
            </p>
            <p>
              ● Use the Service or Customer Data, or any portion thereof, for
              any purpose contrary to Customer's policies or requirements, or in
              any manner not permitted by these Terms;
            </p>
            <p>
              ● Forge any TCP/IP packet header or any part of the header
              information in any email or newsgroup posting, or in any way use
              the Service or Customer Data to send altered, deceptive or false
              source-identifying information;
            </p>
            <p>
              ● Attempt to decipher, decompile, disassemble or reverse engineer
              any of the software used to provide the Service or Customer Data;
            </p>
            <p>
              ● Interfere with, or attempt to interfere with, the access of any
              user, host or network, including, without limitation, sending a
              virus, overloading, flooding, spamming, or mail-bombing the
              Service;
            </p>
            <p>
              ● Collect or store any personally identifiable information from
              the Service from other users of the Service without their express
              permission;
            </p>
            <p>
              ● Impersonate or misrepresent your affiliation with any person or
              entity;
            </p>
            <p>● Violate any applicable law or regulation; or</p>
            <p>
              ● Encourage or enable any other individual to do any of the
              foregoing.
            </p>
            <p>
              Although we're not obligated to monitor access to or use of the
              Service or Customer Data or to review or edit any Customer Data,
              we have the right to do so for the purpose of operating the
              Service, to ensure compliance with these Terms, and to comply with
              applicable law or other legal requirements. We reserve the right,
              but are not obligated, to remove or disable access to any Customer
              Data stored within the Service, at any time and without notice,
              including, but not limited to, if we, at our sole discretion,
              consider any Customer Data to be objectionable or in violation of
              these Terms. We have the right to investigate violations of these
              Terms or conduct that affects the Service. We may also consult and
              cooperate with law enforcement authorities to prosecute users who
              violate the law.
            </p>
            <p><strong>6.</strong> <strong>Confidentiality</strong></p>
            <p>6.1 Definition</p>
            <p>
              <strong></strong>
            </p>
            <p>
              Each party may have access to the other party's information, which
              will be deemed "<strong>Confidential Information</strong>" if
              identified as such by the disclosing party or if the information
              by its nature is normally and reasonably considered confidential,
              such as information regarding product, methodology, research,
              customers, business partners, business plans and any information
              that provides a competitive advantage. Your Confidential
              Information includes Customer Content; Confidential Information of
              the Company includes all non-public aspects of the Service and
              these Terms and all Order Forms (including pricing).
            </p>
            <p>6.2 Standard of Care</p>
            <p>
              <strong></strong>
            </p>
            <p>
              The receiving party will use the same degree of care as it uses to
              protect its own Confidential Information of a like nature, but not
              less than a reasonable degree of care, to (a) prevent use or
              copying of the disclosing party's Confidential Information for any
              purpose other than to perform its obligations or exercise its
              rights as provided under these Terms, and (b) prevent disclosure
              of the disclosing party's Confidential Information other than to
              its employees (or agents bound by similar confidentiality
              obligations) with a need to access the Confidential Information
              for purposes consistent with these Terms. Confidential Information
              will remain the property of the disclosing party and will be
              returned or (along with all copies) destroyed upon request, at
              which time the receiving party will provide to the disclosing
              party a written certification of such return or destruction.
              Without limiting any other provision of these Terms, with respect
              to its obligations under this Section 6, the receiving party will
              be responsible for the acts and omissions of its employees,
              contractors and agents to the same extent as if those acts and
              omissions were those of the receiving party. The parties
              acknowledge, intend, and agree that this Section 6 and the
              obligations set forth herein supersede any and all prior
              confidentiality, non-disclosure, or similar agreements between the
              parties.
            </p>
            <p>6.3 Exceptions</p>
            <p>
              Information will not be deemed Confidential Information hereunder
              if it: (i) it is information that becomes generally known to the
              public through no fault of the receiving party, its affiliates or
              their agents or representatives; (ii) is or becomes known to the
              receiving party without restriction from a third party other than
              as a result of breach of contract or wrongful or tortious act; or
              (iii) is independently developed by the receiving party without
              reference to or reliance on the disclosing party's Confidential
              Information. Confidential Information may be disclosed to the
              extent required by applicable law, provided the disclosing party
              is given reasonable advance notice of such disclosure.
            </p>
            <p><strong>7.</strong> <strong>Payment of Fees</strong></p>
            <p>7.1 Fees</p>
            <p>
              You will pay us the fees set forth in the online checkout or in
              the applicable Order Form (other than fees disputed in good faith)
              in accordance with the terms therein (the "Fees"). All Fees are
              nonrefundable except as expressly provided herein. Any Fees per
              User will apply with respect to the initial number of Users
              identified in the Order Form, plus any additional actual
              Authorized Users. Fees for additional Authorized Users will be
              prorated through the end of the current Term and will be invoiced
              to you in the month after the additional Users are granted access
              to the Software. With respect to any Order Form, we may increase
              the Fees in effect at the start of any Renewal Term by giving you
              at least forty-five (45) days advance written notice of such
              increase.
            </p>
            <p>7.2 Payment</p>
            <p>
              HiThrive accepts certain debit and credit cards as well as other
              forms of cash payment and electronic transfers as acceptable
              payment methods for the Service (each, an "Accepted Payment
              Method"), subject to the procedures and rules contained in these
              Terms and any applicable contract with the provider of the
              Accepted Payment Method. Accepted Payment Methods may vary by
              country or by certain features of the Service and may change from
              time to time. When you purchase with an Accepted Payment Method,
              the fees for your Service will be billed on or near the date of
              your purchase. You agree that we may process your Accepted Payment
              Method on each monthly, annual or other renewal term (based on the
              applicable subscription term), on or near the calendar day
              corresponding to the commencement of your Service subscription, as
              applicable. See "Cancellation" below for information on how to
              cancel a Service subscription. All invoices, fees and any other
              applicable charges are due and payable to us within thirty (30)
              days after the date of our applicable invoice. All Fees and other
              amounts are payable in United States Dollars.
            </p>
            <p>
              In order to facilitate the charging and payment for your
              subscription, you agree that we have permission to retain and/or
              share with financial institutions and payment processing firms
              (including any institutions or firms we retain in the future)
              information regarding your purchase and your submitted payment
              information in order to process your payment, and to use your
              Customer Information to provide you with notices and disclosures
              relating to renewals and recurring charges. If your Service
              subscription began on a day not contained in a subsequent month
              (e.g., your service began on January 30, and there is no February
              30), HiThrive will process your payment on or near the last day of
              such month. (This is what we mean by paying on an autorenewal
              basis.) See below for information describing what you must do to
              change your Accepted Payment Method.
            </p>
            <p>7.3 Paying our Payment Processor</p>
            <p>
              When you purchase the Service, you are granting HiThrive the
              irrevocable right and permission with respect to such purchase to
              provide your personal data and payment information to any
              third-party payment processor we contract with (such as Stripe
              and/or one of its financial service providers) on your behalf, and
              to grant such firm(s) (and/or one of their respective financial
              service providers) the rights to collect, use, retain, and
              disclose such data and information. In addition, you authorize
              HiThrive to (i) obtain and verify your identity as necessary to
              complete financial transactions, and (ii) determine your
              eligibility and authority to complete such purchase.
            </p>
            <p>
              Please note that your obligation for payment to, and relationship
              with, such payment processor is a contractual matter between you
              and such third-party; HiThrive is not a party to, or responsible
              on account of, such contract. While we select our payment
              processors carefully and enter into detailed agreements imposing
              performance obligations (including confidentiality) on them, we
              cannot and do not guarantee their performance.
            </p>
            <p>7.4 Changes to Payment Methods and Account Information</p>
            <p>
              You are responsible for ensuring that your Customer Information,
              including all payment information (address, debit or credit card
              number and expiration date), is accurate and up to date. You may
              change this information at any time by logging into your account
              through the HiThrive website, accessing your account page and
              making the changes or by contacting support@hithrive.com If you
              have any difficulty making any change to the payment method, you
              must notify us by contacting support@hithrive.com and
              demonstrating to our satisfaction your authority to change the
              payment method used for the Service.
            </p>
            <p>
              If your credit card expires or the information is otherwise
              invalid, or you or another party controlling the payment method
              make changes to your billing account too close in time to when we
              charge for the Service subscription (so we cannot reasonably act
              on your changes prior to billing), we will probably not be aware
              of this, and we will bill the current charges to the card we had
              on record; the continuation of your Service subscription
              constitutes your authorization for us to do so, and you remain
              responsible for any uncollected amounts and any charges incurred
              by you or us as a result of billing to an invalid card or other
              payment method.
            </p>
            <p>7.5 Cancellation of a Service Subscription</p>
            <p>
              You may elect to cancel your subscription to the Service at any
              time. Except as otherwise provided in these Terms, any
              cancellation of the Service will be effective as of the end of the
              then-current subscription term. If you desire to cancel your
              subscription for the Service, you must notify us by contacting
              support@hithrive.com and demonstrating to our satisfaction that
              the individual requesting cancellation is authorized to cancel the
              Customer's subscription. If you are using the Service during any
              trial period without charge, and we have provided that you will be
              charged a subscription fee at the end of the trial period, you
              must cancel the Service prior to the end of the trial period in
              order to avoid incurring the charges for the paid subscription for
              the Service.
            </p>
            <p>
              We may cancel any Service subscription you have purchased if you
              fail to pay the applicable subscription fee and any applicable
              Taxes in full and in a timely manner according to these Terms or
              any agreement with a payment processor. A failure to pay occurs in
              any situation where HiThrive has not received timely payment,
              including where any credit card issuer or payer bank refuses to
              transmit the funds that are then due, or the payment processor you
              have contracted with notifies us of your failure to pay. We may,
              in our discretion, elect not to cancel a Service subscription
              immediately for non-payment, in which case you will continue to be
              responsible for the fees due for the duration of the applicable
              subscription. In addition, if you fail to pay for a Service, we
              may elect to suspend your rights to use that Service or we may
              change the type of Service you have to a non-paid version of the
              Service, if applicable. We will endeavor to provide you notice
              promptly after any failure to make a payment to us in full and on
              time, but we are not obligated to do so.
            </p>
            <p>7.6 Costs of Collection; Credit Card Chargebacks</p>
            <p>
              In addition to the published fees for the Service, you agree to
              pay any reasonable costs we incur to collect any unpaid or past
              due amounts, including reasonable attorneys' fees and other
              associated costs. Delinquent payments will bear interest at the
              rate of 1% per month or the highest rate permitted under
              applicable law, whichever is less, from the payment due date until
              paid in full.
            </p>
            <p>
              If you purchase a Service subscription with a credit card and then
              subsequently request your credit card issuer to reverse that
              payment, HiThrive may be charged a fee. Accordingly, in order to
              enable you to pay fees with a credit card, you acknowledge and
              agree that we reserve the right to suspend your ability to use the
              Service until such time as you reimburse us the amount of the fee
              we were charged by the card issuer.
            </p>
            <p>7.7 Taxes</p>
            <p>
              Fees do not include any taxes, levies, duties, export or import
              fees, or other governmental assessments of any nature, including
              but not limited to value-added sales, use or withholding taxes,
              imposed or assessed by any jurisdiction (collectively,
              <strong>"Taxes").</strong>You are responsible for the payment of
              all applicable Taxes (other than Taxes assessable against us based
              on our income, property, franchise or employment) associated with
              your subscription to the Service. You agree to cooperate with us
              and provide us with timely and accurate information as may be
              required for the calculation and withholding of applicable Taxes.
              If we have a legal obligation to collect and remit Taxes for which
              you are responsible, we will invoice you and you will pay us that
              amount unless you provide us with a valid tax exemption
              certificate authorized by the appropriate taxing authority.
            </p>
            <p>
              <strong>8.</strong> <strong>Warranties and Disclaimer</strong>
            </p>
            <p>
              8.1 Each party represents that it has validly entered into these
              Terms and any resulting agreement and is not subject to any
              agreements that conflict with the undertakings provided hereunder.
            </p>
            <p>8.2 We hereby warrant that during the Term:</p>
            <p>
              a) we own, or otherwise properly license, the Software, and the
              Service, and have the full power and authority required to use,
              publish, transfer, and/or license any and all rights and interests
              in and to the Software and Service to you;
            </p>
            <p>
              b) we shall provide and perform all Software and Service in a
              professional and workmanlike manner in accordance with prevailing
              industry standard practices for similar enterprise software as a
              service; and
            </p>
            <p>
              c) all Software, Service, Company Properties, and any other
              content provided to you under these Terms shall comply with
              applicable law.
            </p>
            <p>
              8.3 EXCEPT AS OTHERWISE EXPRESSLY SET FORTH HEREIN, THE SERVICE,
              SOFTWARE, AND ALL COMPANY PROPERTIES ARE PROVIDED "AS IS," AND
              NEITHER PARTY MAKES AND WE SPECIFICALLY DISCLAIM ALL OTHER
              WARRANTIES OF ANY KIND, WHETHER EXPRESSED, IMPLIED OR STATUTORY,
              INCLUDING BUT NOT LIMITED TO IMPLIED WARRANTIES OF
              MERCHANTABILITY, ACCURACY, FITNESS FOR A PARTICULAR PURPOSE AND
              NON-INFRINGEMENT, TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE
              LAW. WE DO NOT WARRANT THAT THE SERVICE, SOFTWARE, OR COMPANY
              PROPERTIES WILL BE ERROR-FREE OR UNINTERRUPTED. YOU AGREE THAT WE
              ARE NOT LIABLE FOR ANY CLAIMS ARISING FROM ANY ADVICE PROVIDED BY
              US IN CONNECTION WITH THE SERVICES. IMPLEMENTATION AND ADVISORY
              SERVICES ARE PROVIDED AT YOUR OWN RISK. FURTHER, ANY CONFLICTING
              TERM HEREIN NOTWITHSTANDING, WHERE APPLICABLE, COMPLIMENTARY
              SERVICES ARE ACCEPTED BY CUSTOMER AS-IS AND ARE NOT SUBJECT TO ANY
              SERVICE LEVEL AGREEMENT OR PRODUCT PERFORMANCE WARRANTIES.
            </p>
            <p>8.4 You hereby warrant that:</p>
            <p>
              a) you own, or otherwise properly license, the Customer Content;
            </p>
            <p>
              b) you have the full power and authority to make the license
              grants hereunder; and
            </p>
            <p>
              c) the Customer Content provided to us under these Terms shall
              comply with any applicable law.
            </p>
            <p><strong>9.</strong> <strong>Indemnification</strong></p>
            <p>9.1 By us</p>
            <p>
              We will defend, indemnify, and hold harmless you and your
              officers, directors, employees and agents from and against all
              claims, damages, losses and external expenses (including
              reasonable attorneys' fees), except where caused in whole or in
              part by your use of the Service in violation of these Terms,
              arising out of any claim by any third party to the extent such
              claim:
            </p>
            <p>
              a) alleges that the Service or Software (in each case as made
              available to you for use in accordance with these Terms) infringes
              or misappropriates any such third party's intellectual property
              rights, under the Uniform Trade Secrets Act, U.S. patent,
              copyright, trademark or other applicable intellectual property
              law. Notwithstanding the foregoing, we will have no obligation
              under this paragraph and will not otherwise be liable to the
              extent the claim is based on: (i) use of the Service or Software
              in combination with any hardware, software, data, content, or
              other component not provided by us; (ii) Customer Content; or
              (iii) use of the Service or Software other than in accordance with
              these Terms and the Documentation; or
            </p>
            <p>b) is based on or alleges our violation of applicable laws.</p>
            <p>9.2 By you</p>
            <p>
              You will defend, indemnify and hold harmless us and our officers,
              directors, employees<strong></strong>and agents from and against
              all claims, damages, losses and external expenses (including
              reasonable attorneys' fees) arising out of any claim by any third
              party to the extent such claim is based on or related to: your use
              of the Software or Service in violation of these Terms, or other
              than in accordance with any advice, training, materials or
              resources, or implementation services provided by us in connection
              with the Service; your Customer Content; or your violation of
              applicable law or the rights of any third party.
            </p>
            <p>9.3 Procedures</p>
            <p>
              The person or entity seeking indemnification hereunder (the
              <strong> "Indemnified Person" </strong>
              ) will provide detailed written notice to the indemnifying party
              promptly after learning of the claim, and the indemnifying party
              will not be obligated to indemnify only to the extent it is
              materially prejudiced by any delay in such notice. The
              indemnifying party will have the right to assume control of the
              defense and settlement of the claim (provided that the prior,
              written agreement of the Indemnified Person will be required in
              order to enter into any settlement or compromise that does not
              include a full release of the Indemnified Person or that requires
              any payment or admission of wrongdoing by the Indemnified Person),
              in which case the Indemnified Person (i) will provide reasonable
              assistance at the indemnifying party's reasonable expense and (ii)
              may employ counsel at its own expense. If we believe our
              intellectual property is or may become the subject of a claim of
              infringement or misappropriation, we may, at our option and
              expense, procure for the right to use the intellectual property,
              or modify or replace the intellectual property to make it
              non-infringing and functionally equivalent. If we reasonably
              conclude that neither of these alternatives is reasonably
              available, we may require the return or destruction of its
              intellectual property upon written request and the termination of
              these Terms to the extent performance is based upon or involves
              the use of such intellectual property, in which case you would be
              entitled to a refund of any prepaid fees for the period after
              termination.
            </p>
            <p><strong>10.</strong> <strong>Limitation of Liability</strong></p>
            <p>
              EXCEPT FOR THE INDEMNIFICATION OBLIGATIONS HEREIN, TO THE MAXIMUM
              EXTENT PERMITTED BY APPLICABLE LAW, NEITHER PARTY WILL BE LIABLE
              FOR ANY SPECIAL, INDIRECT, EXEMPLARY, PUNITIVE, INCIDENTAL OR
              CONSEQUENTIAL DAMAGES (INCLUDING BUT NOT LIMITED TO LOSS OF DATA,
              USE OR PROFIT, PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES, OR
              BUSINESS INTERRUPTION) ARISING OUT OF OR RELATING TO THE SERVICE
              OR THESE TERMS, REGARDLESS OF THE CAUSE OF ACTION OR THE THEORY OF
              LIABILITY, WHETHER IN TORT, CONTRACT, OR OTHERWISE, EVEN IF
              ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
            </p>
            <p>
              EACH PARTY'S MAXIMUM AGGREGATE LIABILITY ARISING OUT OF OR
              RELATING TO THE SERVICE OR THESE TERMS WILL NOT EXCEED THE
              SUBSCRIPTION FEES PAID OR PAYABLE BY YOU DURING THE THEN-CURRENT
              OR MOST RECENT SUBSCRIPTION TERM PRIOR TO THE INITIAL EVENT
              CAUSING OR RESULTING IN SUCH LIABILITY, OR ONE HUNDRED DOLLARS
              ($100), IF YOU HAVE NOT HAD ANY PAYMENT OBLIGATIONS TO HITHRIVE,
              AS APPLICABLE. REGULATIONS AND JUDICIAL PRECEDENT IN CERTAIN
              STATES LIMIT THE MAXIMUM AMOUNT THAT DAMAGES CAN BE LIMITED BY
              OPERATION OF CONTRACT. IF UNDER SUCH CIRCUMSTANCES, THE FOREGOING
              LIMITATION OF LIABILITY IS DEEMED INEFFECTIVE AS A MATTER OF LAW,
              IT SHOULD INSTEAD BE CONSTRUED TO LIMIT LIABILITY TO THE MINIMUM
              AMOUNT THAT WOULD BE CONSISTENT WITH SUCH LAWS OR PRECEDENT WHILE
              STILL GIVING EFFECT TO THE INTENTION OF THIS CLAUSE.
            </p>
            <p>
              THE EXCLUSIONS AND LIMITATIONS OF DAMAGES SET FORTH ABOVE ARE
              FUNDAMENTAL ELEMENTS OF THE BASIS OF THE BARGAIN BETWEEN HITHRIVE
              AND YOU.
            </p>
            <p><strong>11.</strong> <strong>Dispute Resolution</strong></p>
            <p>11.1 Arbitration</p>
            <p>
              The parties will attempt in good faith to resolve any dispute
              arising out of or relating to the Service or these terms by
              negotiation. Any such dispute not so resolved will be resolved by
              binding arbitration administered by the American Arbitration
              Association under its Commercial Arbitration Rules (except to the
              extent that such rules are inconsistent with the provisions set
              forth herein). Such dispute will be heard in New York County, New
              York, United States, by one neutral arbitrator. The arbitrator(s)
              will have the authority to award compensatory damages only, and
              will not have the authority to consolidate actions or claims. The
              award rendered by the arbitrator(s) will be final and binding, and
              judgment upon such award may be entered in any court having
              jurisdiction thereof. Notwithstanding any other provision herein,
              any demand by a party for arbitration must be made in writing to
              the other party within the period prescribed by the state or
              federal statute of limitations applicable to the claim upon which
              the demand is based. If demand is made after such period, the
              claim will be void and deemed waived. The existence, conduct and
              content of such arbitration will be kept confidential, and neither
              party will disclose the same to any person other than its
              attorneys, auditors, financial advisors, assignees or potential
              assignees, except as may be required by law or governmental
              authority or for financial reporting purposes.
            </p>
            <p>11.2 Limitations Period</p>
            <p>
              Regardless of any statute or law to the contrary, any claim or
              cause of action arising out of or related to these Terms or the
              use of the Service must be filed or otherwise commenced within one
              (1) year after such claim or cause of action arose or be forever
              barred.
            </p>
            <p>11.3 Injunctive Relief</p>
            <p>
              Notwithstanding any other provision hereof, either party may seek
              to enforce its rights hereunder with respect to the protection of
              its Confidential Information or intellectual property through
              temporary or permanent injunctive relief, which will be in
              addition to any other available relief and which will not require
              a bond or security. Each party acknowledges that any breach by a
              party of its obligations hereunder with respect to the
              Confidential Information or intellectual property rights of the
              other party might constitute immediate, irreparable harm to such
              other party for which monetary damages would be an inadequate
              remedy.
            </p>
            <p><strong>12.</strong> <strong>General Terms</strong></p>
            <p>12.1 Complete Agreement</p>
            <p>
              These Terms together with any applicable Order Form constitute the
              entire and exclusive understanding and agreement between HiThrive
              and you regarding the Service, and these Terms supersede and
              replace any and all prior oral or written understandings or
              agreements between HiThrive and you regarding the Service.
              However, to the extent of any conflict or inconsistency between
              these Terms and any Order Form entered into between the parties,
              the terms of such Order Form shall control. Notwithstanding any
              language to the contrary therein, no terms or conditions stated in
              a Customer purchase order, vendor onboarding process or web
              portal, or any other Customer order documentation (excluding a
              HiThrive-accepted Order Form) will be incorporated into or form
              any part of these Terms, and all such terms or conditions will be
              null and void.
            </p>
            <p>12.2 Severability</p>
            <p>
              If any provision of these Terms is, for any reason, held to be
              invalid, illegal, or unenforceable, the other provisions of these
              Terms will remain enforceable, and the invalid or unenforceable
              provision will be deemed modified so that it is valid and
              enforceable to the maximum extent permitted by law.
            </p>
            <p>12.3 Assignment</p>
            <p>
              Neither party may assign or otherwise transfer these Terms or any
              rights or obligations hereunder without the written consent of the
              other party, except that either party may, without such consent,
              assign or transfer these Terms to an Affiliate or a purchaser of
              all or substantially all of its assets or to a successor
              organization by merger, consolidation, change of control,
              conversion or otherwise. These Terms are binding upon and inure to
              the benefit of the parties and their respective successors and
              permitted assigns.
            </p>
            <p>12.4 Notices</p>
            <p>
              Any notice required or permitted under these Terms must be in
              writing and delivered by email (i) to you at the email address
              included within your Customer Information or (ii) to HiThrive at
              support@hithrive.com. We may also elect to provide notice to you
              by delivering a message to one or more Users within the Service if
              the initial Administrator is no longer a User within Customer's
              subscription, and you have not provided us with the identity of a
              replacement Administrator. You agree that all notices and other
              communications that we provide to you electronically satisfy any
              legal requirement that such communications be in writing. The date
              of receipt will be deemed the date on which such notice is
              transmitted.
            </p>
            <p>12.5 Waiver</p>
            <p>
              Any provision of these Terms may be waived only in a writing
              signed by the party to be bound thereby. Any waiver or failure to
              enforce any provision of these Terms on one occasion will not be
              deemed a waiver of any other provision or of such provision on any
              other occasion.
            </p>
            <p>12.6 Governing Law</p>
            <p>
              These Terms and any action related thereto will be governed and
              interpreted by and under the laws of the State of New York,
              consistent with the Federal Arbitration Act, without giving effect
              to any principles that provide for the application of the law of
              another jurisdiction. The United Nations Convention on Contracts
              for the International Sale of Goods does not apply to these Terms.
            </p>
            <p>12.7 Public Announcements</p>
            <p>
              You grant us the right to use your name, logo, trademarks and/or
              trade names in press releases, webpages, product brochures and
              financial reports indicating that you are a customer of ours. All
              other public statements or releases will require the mutual
              consent of the parties.
            </p>
            <p>12.8 Relationship of Parties</p>
            <p>
              Neither these Terms nor the cooperation of the parties
              contemplated under these Terms will be deemed or construed to
              create any partnership, joint venture or agency relationship
              between the parties. Neither party is, nor will either party hold
              itself out to be, vested with any power or right to bind the other
              party contractually or act on behalf of the other party as a
              broker, agent or otherwise.
            </p>
            <p>12.9 Force Majeure</p>
            <p>
              Neither party will be liable for any delay or failure to perform
              its obligations hereunder (other than any obligation to make a
              payment) resulting from any cause beyond such party's reasonable
              control, including pandemic, weather, fire, floods, labor
              disputes, riots or civil disturbances, acts of government, and
              acts of war or terrorism, provided that, in every case, the delay
              or failure to perform is beyond the control and without the fault
              or negligence of the party claiming excusable delay and that such
              party cures the breach as soon as possible after the occurrence of
              the unforeseen event.
            </p>
            <p>
              <strong>13.</strong> <strong>Applicable Customer Terms</strong>
            </p>
            <p>
              The following Applicable Customer Terms shall automatically apply
              to the contract between HiThrive and any government Customer
              described below, in which case any conflict between the Applicable
              Customer Terms and the Terms provided above shall be governed by
              these Applicable Customer Terms:
            </p>
            <p>13.1 U.S. Government Customers</p>
            <p>
              If Customer is a United States government or public entity (or use
              of the Service is for the United States government), the terms of
              this Section apply:
            </p>
            <p>
              a) The Service is a "commercial item," as defined at 48 C.F.R.
              §2.101, and constitutes "commercial computer software" and
              "commercial computer software documentation," as used in 48 C.F.R.
              §12.212 or 48 C.F.R. §227.7202 to §227.7204. This commercial
              computer software and related documentation is provided to end
              users for use, by and on behalf of the United States government,
              with only those rights as are granted to all other end users
              pursuant to the terms and conditions herein.
            </p>
            <p>
              b) The sections in the Terms under the captions "Autorenewal,"
              "Indemnity," and "Dispute, Resolution" and are hereby waived to
              the extent they are inconsistent with federal law.
            </p>
            <p>
              c) We agree that Customer's seals, trademarks, logos, service
              marks, trade names, and the fact that Customer uses our Service,
              will not be used by us in such a manner as to state or imply that
              our products or services are endorsed, sponsored or recommended by
              Customer or by any other element of the United States government,
              or are considered by Customer or the United States government to
              be superior to any other products or services. Except where
              Customer has provided any design or content into the Service, or
              for links to or promotion of such pages, we agree not to display
              any Customer or government seals, trademarks, logos, service
              marks, and trade names on our homepage or elsewhere on one of our
              hosted sites unless permission to do so has been granted by
              Customer or by other relevant federal government authority. We may
              list Customer's name in a publicly available customer list so long
              as the name is not displayed in a more prominent fashion than that
              of any other customer.
            </p>
            <p>13.2 State or Local Government Customers</p>
            <p>
              This Section applies to Customer if Customer is a state or local
              government, but only to the extent the Service is being used in
              the User's official capacity as a state or local government
              official. The sections in the Terms under the captions
              "Autorenewal," "Indemnity," and "Dispute Resolution," will not
              apply to Customer only to the extent Customer's jurisdiction's
              laws prohibit Customer from accepting the requirements in those
              sections.
            </p>
            <p>13.3 HiThrive Not a Government Contractor</p>
            <p>
              Notwithstanding any provision in these Applicable Customer Terms
              to the contrary, HiThrive shall not be considered a government
              contractor for any federal, state, local, or foreign government.
            </p>
            <p>13.4 Healthcare Customers</p>
            <p>
              Unless Customer has entered into a written agreement with HiThrive
              to the contrary, Customer acknowledges that HiThrive is not a
              "Business Associate" as defined in the Health Insurance
              Portability and Accountability Act and related amendments and
              regulations as updated or replaced ("HIPAA"), and that the
              Services are not HIPAA compliant. Customer must not use, disclose,
              transmit or otherwise process any "Protected Health Information"
              as defined in HIPAA ("PHI") through the Services. Customer agrees
              that we cannot support and have no liability for PHI received from
              Customer, notwithstanding anything to the contrary herein.
            </p>
            <p><strong>14.</strong> <strong>Contact Information</strong></p>
            <p>
              If you have any questions about these Terms or the Service, please
              contact HiThrive at support@hithrive.com, phone: 646-484-8574,
              physical address: HiThrive Inc., PO Box 20073, New York, NY 10014.
            </p>
            <p><strong>15.</strong> <strong>Other</strong></p>
            <p>
              Please note that "Slack", "Microsoft Teams", and "Stripe" are
              trademarks of Slack Technologies, Inc., Microsoft Corporation,
              Inc., and Stripe, Inc., respectively, and or their respective
              affiliates in the U.S. and other countries, and other trademarks
              and service marks referenced herein are the trademarks of their
              respective owners. HiThrive does not endorse any of these
              services, and neither Slack, Microsoft Teams, Stripe nor any other
              services mentioned in these Terms should be interpreted as an
              endorsement or recommendation of any HiThrive service, and no
              assumption of any endorsement should be taken from HiThrive's
              integration with or use of any of those services.
            </p>
          </main>

          <footer>
            <div class="form-check">
              <input
                id="tos-confirm"
                v-model="tosToggle"
                :disabled="!tosRead"
                value="tos_toggle"
                type="checkbox"
                class="form-check-input"
              />
              <label
                for="tos-confirm"
                class="form-check-label"
                :style="{ color: tosRead ? '#000' : '#86837e' }"
                >I agree to the terms of service.</label
              >
            </div>
            <button
              class="btn btn-primary"
              :disabled="!tosToggle || loadingTOS || !tosRead"
              @click.prevent="handleAcceptTOS"
            >
              <loading-spinner v-if="loadingTOS" />
              <div v-else id="tos-confirm-text">Continue</div>
            </button>
          </footer>
        </div>
      </div>
    </div>
    <div class="ht-scrim"></div>
  </div>
</template>

<style lang="scss" scoped>
.ht-dialog {
  padding-top: 0 !important;
}

footer {
  display: grid !important;
  grid-template-columns: 1fr min-content !important;
}

.text-rich-text {
  li {
    background-image: url('https://uploads-ssl.webflow.com/640ac19d3a0b7505c7a51772/640f9a87f283437e1e9717cb_Check.svg');
    background-position: 0%;
    background-repeat: no-repeat;
    background-size: 0.875rem;
    padding-left: 1.5rem;
    margin-top: 0.25rem;
    list-style-type: none;
    font-size: 0.875rem;

    strong {
      font-size: 0.875rem !important;
    }
  }

  p {
    margin-bottom: 1rem;
    font-size: 1rem;
  }

  h5 {
    font-family: 'Haffer XH', sans-serif;
  }
}

.w-richtext {
  ol,
  ul {
    overflow: hidden;
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding-left: 1.5rem;
  }
}

/* stylelint-disable */
.legal-doc_content-top {
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;
  display: flex;
}
.legal-doc_date-updated-wrap {
  margin-top: 0.4rem;
  display: flex;
  column-gap: 6px;
}

.text-weight-semibold {
  font-weight: 600;
}
</style>
